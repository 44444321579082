import { useHistoryState } from '@everlutionsk/ui';
import { CardWithTabs, Page } from '@everlutionsk/ui-admin';
import { LinkButton } from '@everlutionsk/ui-router';
import React from 'react';
import { routing } from '../../Routes';
import { ExistingJournalistList } from './ExistingJournalistList';
import { RequestedJournalistList } from './RequestedJournalistList';

export function JournalistList() {
  const [tab, setTab] = useHistoryState<'request' | 'existing'>('company.tab', 'request');

  return (
    <Page
      title="Journalists"
      breadcrumbs={[{ label: 'journalists' }]}
      action={
        <LinkButton variant="contained" color="primary" to={routing.journalists.add}>
          Invite Journalist
        </LinkButton>
      }
    >
      <CardWithTabs
        tabs={{
          value: tab,
          onChange: setTab,
          tabs: [
            {
              value: 'request',
              label: 'Requests',
              node: <RequestedJournalistList />
            },
            {
              value: 'existing',
              label: 'Existing',
              node: <ExistingJournalistList />
            }
          ]
        }}
      />
    </Page>
  );
}

import { gql } from '@apollo/client';
import { TableRowNavigation } from '@app/admin/src/components/TableRowNavigation';
import { Table } from '@everlutionsk/ui';
import { Card, CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { Box, styled } from '@mui/material';
import React from 'react';
import { formatDateTime, timeTo, toPlatform } from '../../../helpers';
import { routing } from '../../../Routes';

interface Props {
  readonly journalistId: string;
}

export function VoiceNoteList({ journalistId }: Props) {
  const { data, pagination } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        journalistId
      }
    }),
    total: item => item.voiceNoteMany.page.total
  });

  return (
    <Card>
      <CardContent>
        <TableTitle total={data?.voiceNoteMany.page.total} title="Voice Notes" />
        <TableWrapper>
          <Table
            items={data?.voiceNoteMany.page.items}
            pagination={pagination}
            columns={[
              {
                label: 'Title',
                render: ({ title, id }) => (
                  <Link to={routing.journalists.voiceNote(journalistId, id)}>{title}</Link>
                )
              },
              {
                label: 'Sent',
                render: ({ createdAt }) => formatDateTime(createdAt)
              },
              {
                label: 'Locations',
                render: ({ locations }) => locations.join(', ')
              },
              {
                label: 'Deadline',
                render: ({ deadline }) => timeTo(new Date(deadline))
              },
              {
                label: 'Replies',
                render: item => item.replyMany.page.total
              },
              {
                label: 'Platform',
                render: ({ platform }) => toPlatform(platform)
              },
              {
                label: 'OS',
                render: ({ platformVersion }) => platformVersion
              },
              {
                label: 'App',
                render: ({ appVersion }) => appVersion
              },
              {
                align: 'right',
                label: '',
                render: ({ id }) => (
                  <TableRowNavigation to={routing.journalists.voiceNote(journalistId, id)} />
                )
              }
            ]}
          />
        </TableWrapper>
      </CardContent>
    </Card>
  );
}

const query = gql<VoiceNoteManyQueryGQL>`
  query VoiceNoteManyQuery($input: VoiceNoteManyInput!) {
    voiceNoteMany(input: $input) {
      unread
      page {
        total
        items {
          id
          title
          deadline
          locations
          createdAt
          platform
          platformVersion
          appVersion
          replyMany(input: { pagination: { limit: 1, offset: 0 } }) {
            unread
            page {
              total
              items {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const TableWrapper = styled(Box)`
  .MuiTableCell-alignLeft {
    min-width: 0 !important;
  }
`;

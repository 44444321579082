import { gql } from '@apollo/client';
import { countries } from '@app/admin/common/countries';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  createFormSpec,
  Fields,
  SelectField,
  SubmitButton,
  TextField
} from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate } from '@everlutionsk/ui-router';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { routing } from '../../Routes';

interface Props {
  readonly id: string;
}

export function AddJournalist() {
  const navigate = useNavigate();

  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Invite email was successfully sent!',
    errorMsg: 'An account with the given email already exist!',
    onCompleted: () => navigate(routing.journalists.all)
  });

  return (
    <Page
      title="Add Journalist"
      breadcrumbs={[{ label: 'Journalist', to: routing.journalists.all }, { label: `Add` }]}
    >
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Formik
                {...formSpec({
                  firstName: '',
                  lastName: '',
                  jobTitle: '',
                  publication: '',
                  country: 'United Kingdom',
                  email: '',
                  phone: ''
                })}
                onSubmit={({ firstName, lastName, jobTitle, publication, country, email, phone }) =>
                  mutate({
                    variables: {
                      input: {
                        firstName,
                        lastName,
                        jobTitle,
                        publication,
                        country,
                        email,
                        phone
                      }
                    }
                  })
                }
              >
                <Form>
                  <Fields>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid item xs={6}>
                        <TextField fullWidth name="firstName" label="First name" />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField fullWidth name="lastName" label="Last name" />
                      </Grid>
                    </Grid>

                    <TextField fullWidth name="jobTitle" label="Job title" />

                    <TextField fullWidth name="publication" label="Publication" />

                    <SelectField
                      name="country"
                      label="Country"
                      options={countries.map(item => ({
                        label: item.label,
                        value: item.label
                      }))}
                    />

                    <TextField fullWidth name="email" label="Email" />

                    <TextField fullWidth name="phone" label="Telephone" />
                  </Fields>

                  <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" justifyContent="flex-start" my={2}>
                      <SubmitButton color="primary" variant="contained" size="medium">
                        Send Invite
                      </SubmitButton>
                      <Box ml={2}>
                        <LinkButton
                          color="primary"
                          variant="outlined"
                          size="medium"
                          to={routing.journalists.all}
                        >
                          Cancel
                        </LinkButton>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Formik>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  jobTitle: yup.string().trim(),
  publication: yup.string().trim(),
  country: yup.string(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim(),
  phone: yup.string().trim()
});

const mutation = gql<AddJournalistGQL>`
  mutation AddJournalist($input: InviteJournalistInput!) {
    inviteJournalist(input: $input) {
      id
      email
      firstName
      lastName
      jobTitle
      phone
      country
      status
    }
  }
`;

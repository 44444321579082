import { useHistoryState } from '@everlutionsk/ui';
import { CardWithTabs, Page } from '@everlutionsk/ui-admin';
import { LinkButton } from '@everlutionsk/ui-router';
import React from 'react';
import { routing } from '../../Routes';
import { ExistingManagerList } from './ExistingManagerList';
import { RequestedManagerList } from './RequestedManagerList';
import { TrialManagerList } from './TrialManagerList';

export function ManagerList() {
  const [tab, setTab] = useHistoryState<'request' | 'existing' | 'trial'>('company.tab', 'request');

  return (
    <Page
      title="PRs"
      breadcrumbs={[{ label: 'prs' }]}
      action={
        <LinkButton variant="contained" color="primary" to={routing.managers.add}>
          Invite PR
        </LinkButton>
      }
    >
      <CardWithTabs
        tabs={{
          value: tab,
          onChange: setTab,
          tabs: [
            {
              value: 'request',
              label: 'Requests',
              node: <RequestedManagerList />
            },
            {
              value: 'trial',
              label: 'Trial',
              node: <TrialManagerList />
            },
            {
              value: 'existing',
              label: 'Existing',
              node: <ExistingManagerList />
            }
          ]
        }}
      />
    </Page>
  );
}

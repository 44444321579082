import { gql, useQuery } from '@apollo/client';
import { countries } from '@app/admin/common/countries';
import { populated } from '@everlutionsk/helpers';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  ConditionalForm,
  createFormSpec,
  Fields,
  RadioField,
  SelectField,
  SubmitButton,
  TextField
} from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { DangerZone } from '../../components/DangerZone';
import { ImageField } from '../../components/imageField';
import { toFileUrl } from '../../file';
import { managerFragment } from '../../fragments/manager';
import { routing } from '../../Routes';

export function EditManager() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { id } });

  const [editProfile] = useFlashMutation(editMutation, {
    successMsg: 'Profile was successfully saved!',
    errorMsg: 'Profile was not saved!',
    onCompleted: () => navigate(routing.managers.detail(id))
  });

  const [deleteManager] = useFlashMutation(deleteMutation, {
    variables: { id },
    successMsg: 'Account was successfully deleted!',
    errorMsg: 'Account can not be deleted!',
    onCompleted: () => navigate(routing.managers.all)
  });

  return (
    <Page
      title={data?.managerOne.fullName}
      breadcrumbs={[
        { label: 'Prs', to: routing.managers.all },
        { label: `Detail`, to: routing.managers.detail(id) },
        { label: 'Edit' }
      ]}
    >
      <Card>
        <CardContent>
          {populated(data) && (
            <Grid container>
              <Grid item xs={12}>
                {populated(data) && (
                  <Formik
                    {...formSpec({
                      file: data.managerOne.fileIdentifier
                        ? toFileUrl({
                            file: {
                              id: data.managerOne.fileIdentifier,
                              mutation: `resize_400x400min,crop_400x400`
                            }
                          })
                        : null,
                      firstName: data.managerOne.firstName,
                      lastName: data.managerOne.lastName,
                      email: data.managerOne.email,
                      licence: data.managerOne.licence,
                      jobTitle: data.managerOne.jobTitle ?? '',
                      company: data.managerOne.company ?? '',
                      brand: data.managerOne.brand ?? '',
                      country: data.managerOne.country ?? '',
                      phone: data.managerOne.phone ?? '',
                      licenceExpiredAt: data.managerOne.licenceExpiredAt
                        ? format(new Date(data.managerOne.licenceExpiredAt), 'yyyy-MM-dd')
                        : undefined
                    })}
                    onSubmit={({
                      file,
                      firstName,
                      lastName,
                      jobTitle,
                      company,
                      brand,
                      country,
                      email,
                      phone,
                      licence,
                      licenceExpiredAt
                    }) =>
                      editProfile({
                        variables: {
                          input: {
                            id,
                            firstName,
                            lastName,
                            jobTitle,
                            company,
                            brand,
                            country,
                            email,
                            phone,
                            licence,
                            fileIdentifier: file != null ? file.id : null,
                            licenceExpiredAt: licenceExpiredAt
                              ? new Date(licenceExpiredAt).toISOString()
                              : undefined
                          }
                        }
                      })
                    }
                  >
                    {({ values }) => (
                      <ConditionalForm>
                        <Box mb={3}>
                          <Typography variant="caption" color="text.disabled">
                            Profile picture
                          </Typography>
                          <ImageField name="file" height={200} label="" width={200} />
                        </Box>
                        <Box mb={1}>
                          <Typography variant="caption" color="text.disabled">
                            Personal info
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Fields>
                              <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={6}>
                                  <TextField fullWidth name="firstName" label="First name" />
                                </Grid>

                                <Grid item xs={6}>
                                  <TextField fullWidth name="lastName" label="Last name" />
                                </Grid>
                              </Grid>

                              <TextField name="jobTitle" label="Job title" />

                              <TextField name="company" label="Agency / Company" />

                              <TextField name="brand" label="Brand (if different)" />

                              <SelectField
                                name="country"
                                label="Country"
                                options={countries.map(item => ({
                                  label: item.label,
                                  value: item.label
                                }))}
                              />

                              <TextField name="email" label="Email" />

                              <TextField name="phone" label="Telephone" />

                              <Box display="flex" alignItems="center">
                                <RadioField
                                  name="licence"
                                  label="Licence"
                                  options={[
                                    {
                                      label: 'Trial',
                                      value: 'trial'
                                    },
                                    {
                                      label: 'Full',
                                      value: 'full'
                                    }
                                  ]}
                                />
                                {values.licence === 'trial' && (
                                  <TextField
                                    name="licenceExpiredAt"
                                    label="Trial end date"
                                    type={'date'}
                                  />
                                )}
                              </Box>
                            </Fields>

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              <Box display="flex" my={2}>
                                <SubmitButton color="primary" variant="contained" size="medium">
                                  Save
                                </SubmitButton>
                                <Box ml={2}>
                                  <LinkButton
                                    color="primary"
                                    variant="outlined"
                                    size="medium"
                                    to={routing.managers.all}
                                  >
                                    Cancel
                                  </LinkButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </ConditionalForm>
                    )}
                  </Formik>
                )}
              </Grid>
              <Grid item xs={8}>
                <DangerZone description="Once you delete account, there is no going back. Please be certain.">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      deleteManager();
                    }}
                  >
                    Delete user
                  </Button>
                </DangerZone>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  jobTitle: yup.string().trim(),
  company: yup.string().trim(),
  brand: yup.string().trim(),
  country: yup.string(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim(),
  phone: yup.string().trim(),
  licence: yup.string(),
  file: yup.string().nullable(),
  licenceExpiredAt: yup.date().when('licence', {
    is: 'trial',
    then: schema => schema.min(new Date(), 'Trial end date must be in the future')
  })
});

const editMutation = gql<EditManagerGQL>`
  mutation EditManager($input: EditManagerInput!) {
    editManager(input: $input) {
      ... on Manager {
        id
        firstName
        lastName
        fullName
        jobTitle
        company
        brand
        country
        phone
        email
        showPhone
        showEmail
        licence
        status
        invitedAt
        fileIdentifier
      }
    }
  }
`;

const deleteMutation = gql<DeleteManagerGQL>`
  mutation DeleteManager($id: ID!) {
    deleteManager(id: $id)
  }
`;

const query = gql<ManagerOneEditProfileGQL>`
  query ManagerOneEditProfile($id: ID!) {
    managerOne(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export function DangerZone({
  children,
  description
}: {
  children: ReactNode;
  description: string;
}) {
  return (
    <Box
      mt={3}
      sx={{
        border: '1px dashed #B60808',
        p: 3
      }}
    >
      <Box mb={2}>
        <Typography color="error">DANGER ZONE!</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
      {children}
    </Box>
  );
}

import { gql } from '@apollo/client';
import { countries } from '@app/admin/common/countries';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  ConditionalForm,
  createFormSpec,
  Fields,
  RadioField,
  SelectField,
  SubmitButton,
  TextField
} from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate } from '@everlutionsk/ui-router';
import { Box, Grid } from '@mui/material';
import { addMonths, format } from 'date-fns';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { routing } from '../../Routes';

export function AddManager() {
  const navigate = useNavigate();

  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Invite email was successfully sent!',
    errorMsg: 'An account with the given email already exist!',
    onCompleted: () => navigate(routing.managers.all)
  });

  return (
    <Page
      title="Add PR user"
      breadcrumbs={[{ label: 'Prs', to: routing.managers.all }, { label: `Add` }]}
    >
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Formik
                {...formSpec({
                  firstName: '',
                  lastName: '',
                  jobTitle: '',
                  company: '',
                  brand: '',
                  country: 'United Kingdom',
                  email: '',
                  phone: '',
                  licence: 'trial',
                  licenceExpiredAt: format(new Date('2023-01-01'), 'yyyy-MM-dd')
                })}
                onSubmit={({
                  firstName,
                  lastName,
                  jobTitle,
                  company,
                  brand,
                  country,
                  email,
                  phone,
                  licence,
                  licenceExpiredAt
                }) =>
                  mutate({
                    variables: {
                      input: {
                        firstName,
                        lastName,
                        jobTitle,
                        company,
                        brand,
                        country,
                        email,
                        phone,
                        licence,
                        licenceExpiredAt: new Date(licenceExpiredAt).toISOString()
                      }
                    }
                  })
                }
              >
                {({ values }) => (
                  <ConditionalForm>
                    <Fields>
                      <Grid container spacing={2} sx={{ mb: 1 }}>
                        <Grid item xs={6}>
                          <TextField fullWidth name="firstName" label="First name" />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField fullWidth name="lastName" label="Last name" />
                        </Grid>
                      </Grid>

                      <TextField fullWidth name="jobTitle" label="Job title" />

                      <TextField fullWidth name="company" label="Agency / Company" />

                      <TextField fullWidth name="brand" label="Brand" />

                      <SelectField
                        name="country"
                        label="Country"
                        options={countries.map(item => ({
                          label: item.label,
                          value: item.label
                        }))}
                      />

                      <TextField fullWidth name="email" label="Email" />

                      <TextField fullWidth name="phone" label="Telephone" />

                      <Box display="flex" alignItems="center">
                        <RadioField
                          name="licence"
                          label="Licence"
                          options={[
                            {
                              label: 'Trial',
                              value: 'trial'
                            },
                            {
                              label: 'Full',
                              value: 'full'
                            }
                          ]}
                        />
                        {values.licence === 'trial' && (
                          <TextField name="licenceExpiredAt" label="Trial end date" type={'date'} />
                        )}
                      </Box>
                    </Fields>

                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                      <Box display="flex" justifyContent="flex-start" my={2}>
                        <SubmitButton color="primary" variant="contained" size="medium">
                          Send Invite
                        </SubmitButton>
                        <Box ml={2}>
                          <LinkButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            to={routing.managers.all}
                          >
                            Cancel
                          </LinkButton>
                        </Box>
                      </Box>
                    </Box>
                  </ConditionalForm>
                )}
              </Formik>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  jobTitle: yup.string().trim(),
  company: yup.string().trim(),
  brand: yup.string().trim(),
  country: yup.string(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim(),
  phone: yup.string().trim(),
  licence: yup.string(),
  licenceExpiredAt: yup.date().min(new Date(), 'Trial end date must be in the future')
});

const mutation = gql<AddManagerGQL>`
  mutation AddManager($input: InviteManagerInput!) {
    inviteManager(input: $input) {
      id
      firstName
      lastName
      jobTitle
      company
      country
      email
      phone
      licenceExpiredAt
    }
  }
`;

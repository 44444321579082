import { gql, useMutation } from '@apollo/client';
import { CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { ReactNode, useState } from 'react';
import { ConfirmDialogButton } from '../../components/ConfirmDialog';
import { TableWrapper } from '../../components/TableWrapper';
import { formatDateTime } from '../../helpers';
import { routing } from '../../Routes';

interface Props {
  readonly managerId: string;
}

export function NotRelevantList({ managerId }: Props) {
  const { data, refetch } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        managerId,
        notRelevant: true
      },
      id: managerId
    }),
    total: item => item.voiceNoteMany.page.total
  });

  const [reEnable] = useMutation(mutation, {
    onCompleted: () => {
      refetch();
    }
  });

  return (
    <>
      <CardContent>
        <TableTitle title="Not relevant" total={data?.voiceNoteMany.page.total} />
        <TableWrapper>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Journalists</TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.voiceNoteMany.page.items.map(row => (
                <Row
                  key={row.id}
                  row={{
                    dateSent: row.managerReactedAt ? formatDateTime(row.managerReactedAt) : '-',
                    journalist: (
                      <Link to={routing.journalists.detail(row.journalist.id)}>
                        {row.journalist.fullName}
                      </Link>
                    ),
                    title: row.title,
                    options: (
                      <ConfirmDialogButton
                        variant="text"
                        color="error"
                        size="small"
                        dialog={{
                          title: 'Are you sure?',
                          content: 'By confirming you will reenable voice note for current manager',
                          onConfirm: async () => {
                            await reEnable({
                              variables: { managerId, voiceNoteId: row.id }
                            });
                          }
                        }}
                      >
                        re-enable
                      </ConfirmDialogButton>
                    )
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </>
  );
}

const query = gql<NotRelevantListQueryGQL>`
  query NotRelevantListQuery($input: VoiceNoteManyInput!, $id: ID!) {
    voiceNoteMany(input: $input) {
      unread
      page {
        total
        items {
          id
          title
          journalist {
            id
            fullName
          }
          managerReactedAt(id: $id)
        }
      }
    }
  }
`;

const mutation = gql<ReEnableVoiceNoteMutationGQL>`
  mutation ReEnableVoiceNoteMutation($managerId: ID!, $voiceNoteId: ID!) {
    reEnableVoiceNote(managerId: $managerId, voiceNoteId: $voiceNoteId)
  }
`;

function Row(props: {
  row: {
    dateSent: string;
    journalist: ReactNode;
    title: ReactNode;
    options: ReactNode;
  };
}) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' }
        }}
      >
        <TableCell component="th" scope="row">
          {row.dateSent}
        </TableCell>
        <TableCell>{row.journalist}</TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell align="right">{row.options}</TableCell>
      </TableRow>
    </>
  );
}

import { alpha, Box, Typography } from '@mui/material';
import React from 'react';
import { UserStatus } from '../graphql/types';
import { prettifyStatus } from '../prettifyData';

export function StatusBadge({ status }: { status: UserStatus }) {
  const color = chooseColor(status);

  if (color == null) {
    return <Typography variant="body2">{prettifyStatus(status)}</Typography>;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography sx={{ mr: 1 }}>Status:</Typography>
      <Typography
        sx={{
          px: 2,
          backgroundColor: alpha(color, 0.16),
          border: `1px solid ${alpha(color, 0.5)}`
        }}
        variant="body2"
      >
        {prettifyStatus(status)}
      </Typography>
    </Box>
  );
}

function chooseColor(status: UserStatus): string | undefined {
  switch (status) {
    case 'activated':
      return '#30A76E';
    case 'invited':
      return '#1e8ae8';
    case 'requested':
      return '#F8C147';
    case 'suspended':
      return '#f3830c';
    case 'deleted':
      return '#EB5353';

    default:
  }
}

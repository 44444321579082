import { gql, useMutation } from '@apollo/client';
import { ConfirmDialogButton } from '@app/admin/src/components/ConfirmDialog';
import { TableChip } from '@app/admin/src/components/TableChip';
import { AnswerType } from '@app/admin/src/graphql/types';
import { Table as EverlutionTable } from '@everlutionsk/ui';
import { Card, CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { Call as CallIcon, Email, PanTool } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { formatDateTime, toPlatform } from '../../../helpers';
import { routing } from '../../../Routes';

interface Props {
  readonly voiceNoteId: string;
}

export function RepliesList({ voiceNoteId }: Props) {
  const { data, pagination, refetch } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        voiceNoteId
      }
    }),
    total: item => item.replyMany.page.total
  });

  const [deleteReply] = useMutation(mutation, {
    onCompleted: () => {
      refetch();
    }
  });

  return (
    <Card>
      <CardContent>
        <TableTitle title="Replies" total={data?.replyMany.page.total} />
        <TableWrapper>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>PR</TableCell>
                <TableCell>Replied</TableCell>
                <TableCell>Read</TableCell>
                <TableCell>Listened</TableCell>
                <TableCell>Spam / Active</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.replyMany.page.items.map(row => (
                <Row
                  key={row.id}
                  row={{
                    pr: (
                      <Link to={routing.managers.detail(row.manager.id)}>
                        {row.manager.fullName}
                      </Link>
                    ),
                    repliedAt: formatDateTime(row.createdAt),
                    listened: row.listened ? (
                      <TableChip label="Listened" variant="success" />
                    ) : (
                      <TableChip label="Unlistened" variant="warning" />
                    ),
                    read: row.unread ? (
                      <TableChip label="Unread" variant="warning" />
                    ) : (
                      <TableChip label="Read" variant="success" />
                    ),
                    spam: row.flagged ? (
                      <TableChip label="Spam" variant="error" />
                    ) : (
                      <TableChip label="Active" variant="success" />
                    ),
                    options: (
                      <ConfirmDialogButton
                        variant="text"
                        color="error"
                        size="small"
                        dialog={{
                          title: 'Are you sure?',
                          content: 'By confirming you will delete reply',
                          onConfirm: async () => {
                            await deleteReply({ variables: { id: row.id, voiceNoteId } });
                          }
                        }}
                      >
                        Delete
                      </ConfirmDialogButton>
                    )
                  }}
                  collapseData={[
                    {
                      answer: row.answer
                        ? { uri: row.answer.audioFile?.uri, type: row.answer?.type }
                        : undefined,
                      prReplyId: row.audioFile.uri ?? 'None',
                      prResponseData: {
                        platform: toPlatform(row.platform),
                        platformVersion: row.platformVersion ?? '-',
                        appVersion: row.appVersion ?? '-'
                      },
                      answerData: row.answer
                        ? {
                            platform: toPlatform(row.answer.platform),
                            platformVersion: row.answer.platformVersion ?? '-',
                            appVersion: row.answer.appVersion ?? '-'
                          }
                        : undefined
                    }
                  ]}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </Card>
  );
}

const query = gql<RepliesManyQueryGQL>`
  query RepliesManyQuery($input: ReplyManyInput!) {
    replyMany(input: $input) {
      unread
      page {
        total
        items {
          id
          createdAt
          audioFile {
            identifier
            uri
          }
          unread
          listened
          flagged
          answer {
            audioFile {
              identifier
              uri
            }
            text
            type
            createdAt
            platform
            platformVersion
            appVersion
          }
          platform
          platformVersion
          appVersion
          manager {
            id
            fullName
          }
        }
      }
    }
  }
`;

const mutation = gql<DeleteReplyAdminMutationGQL>`
  mutation DeleteReplyAdminMutation($id: ID!, $voiceNoteId: ID!) {
    deleteReplyAdmin(id: $id, voiceNoteId: $voiceNoteId)
  }
`;

export const TableWrapper = styled(Box)`
  .MuiTableCell-alignLeft {
    min-width: 0 !important;
  }
`;

function Row(props: {
  row: {
    pr: ReactNode;
    repliedAt: ReactNode;
    read: ReactNode;
    listened: ReactNode;
    spam: ReactNode;
    options: ReactNode;
  };
  collapseData: Array<{
    prReplyId: string;
    answer:
      | {
          uri?: string;
          type: AnswerType;
        }
      | undefined;
    prResponseData: {
      platform: string;
      platformVersion?: string;
      appVersion?: string;
    };
    answerData:
      | undefined
      | {
          platform: string;
          platformVersion?: string;
          appVersion?: string;
        };
  }>;
}) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.pr}</TableCell>
        <TableCell component="th" scope="row">
          {row.repliedAt}
        </TableCell>
        <TableCell>{row.read}</TableCell>
        <TableCell>{row.listened}</TableCell>
        <TableCell>{row.spam}</TableCell>
        <TableCell align="right">{row.options}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Conversation
              </Typography>
              <EverlutionTable
                items={props.collapseData}
                rowKey={item => item.prReplyId}
                columns={[
                  {
                    label: "Listen (PR's response)",
                    render: item => (
                      <Box display="flex" flexDirection="column">
                        <ReactAudioPlayer src={item.prReplyId} controls />
                        <Box mt={2}>
                          <Typography>Platform: {item.prResponseData.platform}</Typography>
                          <Typography>OS: {item.prResponseData.platformVersion}</Typography>
                          <Typography>App: {item.prResponseData.appVersion}</Typography>
                        </Box>
                      </Box>
                    )
                  },
                  {
                    label: 'Response',
                    render: item => {
                      function toResponse(item) {
                        if (item.answer == null) return 'NONE';

                        if (item.answer.type === 'audio' && item.answer.uri) {
                          return <ReactAudioPlayer src={item.answer.uri} controls />;
                        }
                        if (item.answer.type === 'email') {
                          return (
                            <TableChip
                              icon={
                                <Email
                                  sx={{
                                    width: '15px',
                                    height: '15px'
                                  }}
                                />
                              }
                              label={item.answer.type}
                            />
                          );
                        }
                        if (item.answer.type === 'call') {
                          return (
                            <TableChip
                              icon={
                                <CallIcon
                                  sx={{
                                    width: '15px',
                                    height: '15px'
                                  }}
                                />
                              }
                              label={item.answer.type}
                            />
                          );
                        }
                        if (item.answer.type === 'thankYou') {
                          return (
                            <TableChip
                              icon={
                                <PanTool
                                  sx={{
                                    width: '15px',
                                    height: '15px'
                                  }}
                                />
                              }
                              label={item.answer.type}
                            />
                          );
                        }
                        return item.answer.type.toUpperCase();
                      }

                      if (item.answerData == null) return toResponse(item);

                      return (
                        <Box display="flex" flexDirection="column">
                          {toResponse(item)}
                          <Box mt={2}>
                            <Typography>Platform: {item.answerData.platform}</Typography>
                            <Typography>OS: {item.answerData.platformVersion}</Typography>
                            <Typography>App: {item.answerData.appVersion}</Typography>
                          </Box>
                        </Box>
                      );
                    }
                  }
                ]}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

import { gql, useMutation, useQuery } from '@apollo/client';
import { DangerZone } from '@app/admin/src/components/DangerZone';
import { populated } from '@everlutionsk/helpers';
import { Button, Loading } from '@everlutionsk/ui';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { createFormSpec, Fields, SubmitButton, TextField } from '@everlutionsk/ui-formik';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import * as yup from 'yup';
import { routing } from '../../../Routes';
import { RepliesList } from './RepliesList';

export function VoiceNote() {
  const { id, voiceNoteId } = useParams(['id', 'voiceNoteId']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { id: voiceNoteId } });
  const [edit] = useFlashMutation(mutation, {
    successMsg: 'Voice note was successfully updated!',
    errorMsg: 'Voice note was not updated!'
  });
  const [deleteVoiceNote] = useMutation(deleteMutation, {
    onCompleted: () => {
      navigate(routing.journalists.detail(id));
    }
  });

  if (!populated(data)) {
    return <Loading />;
  }

  return (
    <Page
      title={data.voiceNoteOne.title}
      breadcrumbs={[
        { label: 'Journalists', to: routing.journalists.all },
        { label: data.voiceNoteOne.journalist.fullName, to: routing.journalists.detail(id) },
        { label: 'Voice Notes' },
        { label: data.voiceNoteOne.title }
      ]}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Formik
                {...formSpec({
                  title: data.voiceNoteOne.title,
                  deadline: format(new Date(data.voiceNoteOne.deadline), "yyyy-MM-dd'T'HH:mm"),
                  locations: data.voiceNoteOne.locations
                    ? data.voiceNoteOne.locations.join(', ')
                    : ''
                })}
                onSubmit={formValues =>
                  edit({
                    variables: {
                      input: {
                        id: voiceNoteId,
                        title: formValues.title,
                        deadline: new Date(formValues.deadline).toISOString(),
                        locations: formValues.locations.split(',').map(s => s.trim())
                      }
                    }
                  })
                }
              >
                <Form>
                  <Fields>
                    <TextField name="title" label="Title" />
                    <TextField name="deadline" label="Deadline" type={'datetime-local'} />
                    <TextField name="locations" label="Travel locations (optional)" />
                  </Fields>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box display="flex" my={2}>
                      <SubmitButton color="primary" variant="contained" size="medium">
                        Save changes
                      </SubmitButton>
                    </Box>
                  </Box>
                </Form>
              </Formik>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ width: 'auto' }}>
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <ReactAudioPlayer src={data.voiceNoteOne.audioFile.uri} controls />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <RepliesList voiceNoteId={voiceNoteId} />

          <Grid container>
            <Grid item xs={6}>
              <DangerZone description="Once you delete Voice Note, there is no going back. Please be certain.">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    deleteVoiceNote({ variables: { id: voiceNoteId } });
                  }}
                >
                  Delete Voice Note
                </Button>
              </DangerZone>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  title: yup.string().required("Please enter Voice Note's title").trim(),
  deadline: yup.date().required("Please enter Voice Note's deadline"),
  locations: yup.string().nullable().trim()
});

const query = gql<VoiceNoteOneQueryGQL>`
  query VoiceNoteOneQuery($id: ID!) {
    voiceNoteOne(id: $id) {
      id
      title
      deadline
      createdAt
      locations
      audioFile {
        identifier
        uri
      }
      journalist {
        id
        fullName
      }
    }
  }
`;

const mutation = gql<EditVoiceNoteAdminMutationGQL>`
  mutation EditVoiceNoteAdminMutation($input: EditVoiceNoteAdminInput!) {
    editVoiceNoteAdmin(input: $input) {
      id
      title
      deadline
      createdAt
      locations
      audioFile {
        identifier
        uri
      }
      journalist {
        id
        fullName
      }
    }
  }
`;

const deleteMutation = gql<DeleteVoiceNoteAdminMutationGQL>`
  mutation DeleteVoiceNoteAdminMutation($id: ID!) {
    deleteVoiceNoteAdmin(id: $id)
  }
`;

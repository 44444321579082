import { gql, useQuery } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { LocaleDate } from '@everlutionsk/ui';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { createFormSpec, Fields, SubmitButton, TextField } from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { UserStatus } from '../../graphql/types';
import { routing } from '../../Routes';

export function EditAdmin() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const [resendInvite] = useFlashMutation(resendInviteMutation, {
    successMsg: 'Invitation resend successful!',
    errorMsg: 'User could not be invited!'
  });

  const [editProfile] = useFlashMutation(editMutation, {
    successMsg: 'Profile was successfully saved!',
    errorMsg: 'Profile was not saved!',
    onCompleted: () => navigate(routing.admins.all)
  });

  const { data } = useQuery(query, {
    variables: { id }
  });

  return (
    <Page
      title={`Edit ${data?.adminOne.firstName} ${data?.adminOne.lastName}`}
      breadcrumbs={[{ label: 'Admins', to: routing.admins.all }, { label: `Edit` }]}
    >
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Box mb={3}>
            {data?.adminOne.status === UserStatus.invited && (
              <Box my={2} display="flex">
                <Typography variant="body1">Invite sent:</Typography>
                <Box ml={3} component="span">
                  <Typography variant="body1">
                    <LocaleDate date={data?.adminOne.invitedAt} variant="date" />
                  </Typography>
                </Box>
              </Box>
            )}

            <Box my={1} mb={4} display="flex" flexDirection="row" alignItems="center">
              Last login:
              <Box display="flex" ml={3} component="span">
                {data?.adminOne.status === 'activated' && (
                  <Box display="flex" alignItems="center">
                    <Box mr={5}>
                      {data?.adminOne.lastActiveAt ? (
                        <LocaleDate date={data?.adminOne.lastActiveAt} variant="date" />
                      ) : (
                        <Typography variant="body1">Never</Typography>
                      )}
                    </Box>
                  </Box>
                )}
                {data?.adminOne.status === 'invited' && (
                  <Box display="flex" alignItems="center">
                    <Box mr={5}>
                      <Typography variant="body1">Pending</Typography>
                    </Box>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        resendInvite({
                          variables: {
                            id
                          }
                        })
                      }
                    >
                      Resend invite email
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              {populated(data) && (
                <Formik
                  {...formSpec({
                    firstName: data.adminOne.firstName,
                    lastName: data.adminOne.lastName,
                    email: data.adminOne.email
                  })}
                  onSubmit={({ firstName, lastName, email }) =>
                    editProfile({
                      variables: {
                        input: {
                          id,
                          firstName,
                          lastName,
                          email
                        }
                      }
                    })
                  }
                >
                  <Form>
                    <Fields>
                      <TextField fullWidth name="firstName" label="First name" />

                      <TextField fullWidth name="lastName" label="Last name" />

                      <TextField fullWidth name="email" label="Email" />
                    </Fields>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexWrap="wrap"
                      alignItems="center"
                    >
                      <Box display="flex" my={2}>
                        <SubmitButton color="primary" variant="contained" size="medium">
                          Save
                        </SubmitButton>
                        <Box ml={2}>
                          <LinkButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            to={routing.admins.all}
                          >
                            Cancel
                          </LinkButton>
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                </Formik>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim()
});

const query = gql<AdminOneEditGQL>`
  query AdminOneEdit($id: ID!) {
    adminOne(id: $id) {
      id
      firstName
      lastName
      email
      status
      invitedAt
      lastActiveAt
    }
  }
`;

const resendInviteMutation = gql<ResendAdminInviteGQL>`
  mutation ResendAdminInvite($id: ID!) {
    resendAdminInvite(id: $id) {
      id
      invitedAt
    }
  }
`;

const editMutation = gql<EditAdminGQL>`
  mutation EditAdmin($input: EditAdminInput!) {
    editAdmin(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;

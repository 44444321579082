import { gql } from '@apollo/client';
import { Table, useSearchField } from '@everlutionsk/ui';
import { CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link, LinkButton } from '@everlutionsk/ui-router';
import React from 'react';
import { TableRowNavigation } from '../../components/TableRowNavigation';
import { TableWrapper } from '../../components/TableWrapper';
import { UserStatus } from '../../graphql/types';
import { formatDateTime } from '../../helpers';
import { routing } from '../../Routes';

export function RequestedManagerList() {
  const [term, searchInput] = useSearchField('requestedManagerList.term');
  const { data, pagination } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        status: [UserStatus.requested],
        term
      }
    })
  });

  return (
    <>
      <CardContent>{searchInput}</CardContent>
      <CardContent>
        <TableTitle
          total={data?.managerMany.total}
          title="Requested PR users"
          totalNumberDescription="results matching your search"
        />
        <TableWrapper>
          <Table
            pagination={pagination}
            items={data?.managerMany.items}
            columns={[
              {
                label: 'Name',
                render: ({ fullName, id }) => (
                  <Link to={routing.managers.detail(id)}>{fullName}</Link>
                )
              },
              {
                label: 'Company',
                render: ({ company }) => company
              },
              {
                label: 'Country',
                render: ({ country }) => country
              },
              {
                label: 'Requested at',
                render: ({ requestedAt }) => (requestedAt ? formatDateTime(requestedAt) : '-')
              },
              {
                align: 'right',
                label: '',
                render: ({ id }) => <TableRowNavigation to={routing.managers.detail(id)} />
              }
            ]}
          />
        </TableWrapper>
      </CardContent>
    </>
  );
}

const query = gql<ManagerRequestedManyGQL>`
  query ManagerRequestedMany($input: ManagerManyInput!) {
    managerMany(input: $input) {
      total
      items {
        id
        fullName
        company
        country
        status
        licence
        lastActiveAt
        requestedAt
      }
    }
  }
`;

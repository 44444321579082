import { Link } from '@everlutionsk/ui-router';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';

export function TableRowNavigation({ to }: { to: string }) {
  return (
    <Link to={to}>
      <ArrowForwardIosIcon fontSize="small" />
    </Link>
  );
}

import React from 'react';
import { Route, Routes } from '@everlutionsk/ui-router';
import { routingPatterns } from '../../Routes';
import { AddAdmin } from './AddAdmin';
import { AdminList } from './AdminList';
import { EditAdmin } from './EditAdmin';

export function Admins() {
  return (
    <Routes>
      <Route path={routingPatterns.admins.all} element={<AdminList />} />
      <Route path={routingPatterns.admins.add} element={<AddAdmin />} />
      <Route path={routingPatterns.admins.edit} element={<EditAdmin />} />
    </Routes>
  );
}

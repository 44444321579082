import { gql } from '@apollo/client';
import { Table, useSearchField } from '@everlutionsk/ui';
import { CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link, LinkButton } from '@everlutionsk/ui-router';
import { isEmpty } from 'ramda';
import React from 'react';
import { TableWrapper } from '../../components/TableWrapper';
import { TimeAgo } from '../../components/TimeAgo';
import { Licence, UserStatus } from '../../graphql/types';
import { prettifyStatus } from '../../prettifyData';
import { routing } from '../../Routes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TableRowNavigation } from '../../components/TableRowNavigation';

export function ExistingManagerList() {
  const [term, searchInput] = useSearchField('existingManagerList.term');
  const { data, pagination } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        term,
        licence: Licence.full,
        status: [UserStatus.activated, UserStatus.invited, UserStatus.suspended]
      }
    })
  });

  return (
    <>
      <CardContent>{searchInput}</CardContent>
      <CardContent>
        <TableTitle
          total={data?.managerMany.total}
          title="Existing PR users"
          totalNumberDescription="results matching your search"
        />
        <TableWrapper>
          <Table
            pagination={pagination}
            items={data?.managerMany.items}
            columns={[
              {
                label: 'Name',
                render: ({ fullName, id }) => (
                  <Link to={routing.managers.detail(id)}>{fullName}</Link>
                )
              },
              {
                label: 'Company',
                render: ({ company }) => (company != null && !isEmpty(company) ? company : '-')
              },
              {
                label: 'Country',
                render: ({ country }) => (country != null && !isEmpty(country) ? country : '-')
              },
              {
                label: 'Status',
                render: ({ status }) => prettifyStatus(status)
              },
              {
                label: 'Last login',
                render: ({ lastActiveAt }) =>
                  lastActiveAt ? <TimeAgo iso={lastActiveAt} /> : 'Never'
              },
              {
                label: 'Cognito ID',
                render: ({ cognitoId }) => cognitoId ?? '-'
              },
              {
                align: 'right',
                label: '',
                render: ({ id }) => <TableRowNavigation to={routing.managers.detail(id)} />
              }
            ]}
          />
        </TableWrapper>
      </CardContent>
    </>
  );
}

const query = gql<ManagerManyGQL>`
  query ManagerMany($input: ManagerManyInput!) {
    managerMany(input: $input) {
      total
      items {
        id
        fullName
        company
        country
        status
        licence
        lastActiveAt
        cognitoId
      }
    }
  }
`;

import { alpha } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';

export function TableChip({
  variant,
  label,
  icon
}: {
  variant?: 'success' | 'error' | 'warning' | 'info';
  label: string;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) {
  const color = chooseColor(variant);

  if (color == null) {
    return <Chip icon={icon} label={label} />;
  }

  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: alpha(color, 0.16),
        border: `1px solid ${alpha(color, 0.5)}`
      }}
      label={label}
    />
  );
}

function chooseColor(variant?: 'success' | 'error' | 'warning' | 'info'): string | undefined {
  switch (variant) {
    case 'success':
      return '#30A76E';
    case 'info':
      return '#1e8ae8';
    case 'warning':
      return '#f3830c';
    case 'error':
      return '#EB5353';

    default:
  }
}

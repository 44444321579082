import { gql } from '@apollo/client';
import { LocaleDate, Table } from '@everlutionsk/ui';
import { Card, CardContent, Page, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link, LinkButton } from '@everlutionsk/ui-router';
import React from 'react';
import { TableRowNavigation } from '../../components/TableRowNavigation';
import { TableWrapper } from '../../components/TableWrapper';
import { TimeAgo } from '../../components/TimeAgo';
import { routing } from '../../Routes';

export function AdminList() {
  const { data, pagination } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset }
      }
    })
  });

  return (
    <Page
      breadcrumbs={[{ label: 'Admins' }]}
      title="Admins"
      action={
        <LinkButton variant="contained" color="primary" to={routing.admins.add}>
          Invite Admin
        </LinkButton>
      }
    >
      <Card>
        <CardContent>
          <TableTitle
            total={data?.adminMany.total}
            title="Current admin users"
            totalNumberDescription="results matching your search"
          />
          <TableWrapper>
            <Table
              pagination={pagination}
              items={data?.adminMany.items}
              columns={[
                {
                  label: 'Name',
                  render: ({ firstName, lastName, id }) => (
                    <Link to={routing.admins.edit(id)}>
                      {firstName} {lastName}
                    </Link>
                  )
                },
                {
                  label: 'Email',
                  render: ({ email }) => email
                },
                {
                  label: 'Created at',
                  render: ({ createdAt }) => <LocaleDate date={createdAt} variant="date" />
                },
                {
                  label: 'Last login',
                  render: ({ lastActiveAt }) =>
                    lastActiveAt ? <TimeAgo iso={lastActiveAt} /> : 'Never'
                },
                {
                  align: 'right',
                  label: '',
                  render: ({ id }) => <TableRowNavigation to={routing.admins.edit(id)} />
                }
              ]}
            />
          </TableWrapper>
        </CardContent>
      </Card>
    </Page>
  );
}

const query = gql<AdminManyGQL>`
  query AdminMany($input: AdminManyInput!) {
    adminMany(input: $input) {
      total
      items {
        id
        firstName
        lastName
        email
        createdAt
        firstLoginAt
        lastActiveAt
      }
    }
  }
`;

import { gql, useQuery } from '@apollo/client';
import { countries } from '@app/admin/common/countries';
import { populated } from '@everlutionsk/helpers';
import { Card, CardContent, Cards, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import {
  createFormSpec,
  Fields,
  SelectField,
  SubmitButton,
  TextField
} from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate, useParams } from '@everlutionsk/ui-router';
import { Call as CallIcon, Email, PanTool } from '@mui/icons-material';
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { DangerZone } from '../../components/DangerZone';
import { ImageField } from '../../components/imageField';
import { toFileUrl } from '../../file';
import { journalistFragment } from '../../fragments/journalist';
import { routing } from '../../Routes';

export function EditJournalist() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { id } });

  const [edit] = useFlashMutation(editJournalist, {
    successMsg: 'Profile was successfully saved!',
    errorMsg: 'Profile was not saved!',
    onCompleted: () => navigate(routing.journalists.detail(id))
  });

  const [deleteJournalist] = useFlashMutation(deleteMutation, {
    variables: { id },
    successMsg: 'Account was successfully deleted!',
    errorMsg: 'Account can not be deleted!',
    onCompleted: () => navigate(routing.journalists.all)
  });

  return (
    <Page
      title={data?.journalistOne.fullName}
      breadcrumbs={[
        { label: 'Journalists', to: routing.journalists.all },
        { label: `Detail`, to: routing.journalists.detail(id) },
        { label: 'Edit' }
      ]}
    >
      <Card sx={{ borderTopWidth: '0px' }}>
        {populated(data) && (
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Formik
                  {...formSpec({
                    file: data.journalistOne.fileIdentifier
                      ? toFileUrl({
                          file: {
                            id: data.journalistOne.fileIdentifier,
                            mutation: `resize_400x400min,crop_400x400`
                          }
                        })
                      : null,
                    firstName: data?.journalistOne.firstName,
                    lastName: data?.journalistOne.lastName,
                    email: data.journalistOne.email,
                    jobTitle: data?.journalistOne.jobTitle ?? '',
                    publication: data.journalistOne.publication ?? '',
                    country: data.journalistOne.country ?? '',
                    phone: data.journalistOne.phone ?? '',
                    callTemplate: data.journalistOne.callTemplate ?? '',
                    emailTemplate: data.journalistOne.emailTemplate ?? '',
                    thankTemplate: data.journalistOne.thankTemplate ?? '',
                    activeCallTemplate: data.journalistOne.activeCallTemplate,
                    activeEmailTemplate: data.journalistOne.activeEmailTemplate,
                    activeThankTemplate: data.journalistOne.activeThankTemplate
                  })}
                  onSubmit={({
                    file,
                    firstName,
                    lastName,
                    email,
                    jobTitle,
                    publication,
                    country,
                    phone,
                    callTemplate,
                    emailTemplate,
                    thankTemplate,
                    activeCallTemplate,
                    activeEmailTemplate,
                    activeThankTemplate
                  }) => {
                    return edit({
                      variables: {
                        input: {
                          id,
                          firstName,
                          lastName,
                          email,
                          jobTitle,
                          publication,
                          country,
                          phone,
                          callTemplate,
                          emailTemplate,
                          thankTemplate,
                          activeCallTemplate,
                          activeEmailTemplate,
                          activeThankTemplate,
                          fileIdentifier: file != null ? file.id : null
                        }
                      }
                    });
                  }}
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      <Box sx={{ mb: 3 }}>
                        <Cards>
                          <Card title="Call response template">
                            <CardContent>
                              <Box display="flex" alignItems="center">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Box
                                    mb={1}
                                    display="flex"
                                    border="1px solid black"
                                    width="30px"
                                    height="30px"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <CallIcon style={{ fontSize: '25px' }} />
                                  </Box>
                                  <Switch
                                    checked={values.activeCallTemplate}
                                    onChange={e =>
                                      setFieldValue('activeCallTemplate', e.target.checked)
                                    }
                                    color="primary"
                                  />
                                </Box>
                                <Box width="100%">
                                  <TextField fullWidth multiline rows={3} name="callTemplate" />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>

                          <Card title="Email response template">
                            <CardContent>
                              <Box display="flex" alignItems="center">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Box
                                    mb={1}
                                    display="flex"
                                    border="1px solid black"
                                    width="30px"
                                    height="30px"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Email style={{ fontSize: '25px' }} />
                                  </Box>
                                  <Switch
                                    checked={values.activeEmailTemplate}
                                    onChange={e =>
                                      setFieldValue('activeEmailTemplate', e.target.checked)
                                    }
                                    color="primary"
                                  />
                                </Box>
                                <Box width="100%">
                                  <TextField fullWidth multiline rows={3} name="emailTemplate" />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>

                          <Card title="Thanks response template">
                            <CardContent>
                              <Box display="flex" alignItems="center">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Box
                                    mb={1}
                                    display="flex"
                                    border="1px solid black"
                                    width="30px"
                                    height="30px"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <PanTool style={{ fontSize: '25px' }} />
                                  </Box>
                                  <Switch
                                    checked={values.activeThankTemplate}
                                    onChange={e =>
                                      setFieldValue('activeThankTemplate', e.target.checked)
                                    }
                                    color="primary"
                                  />
                                </Box>
                                <Box width="100%">
                                  <TextField fullWidth multiline rows={3} name="thankTemplate" />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Cards>
                      </Box>

                      <Grid container>
                        <Grid item xs={6}>
                          <Box mb={3}>
                            <Typography variant="caption" color="text.disabled">
                              Profile picture
                            </Typography>
                            <ImageField name="file" height={200} label="" width={200} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box mb={1}>
                        <Typography variant="caption" color="text.disabled">
                          Personal info
                        </Typography>
                      </Box>
                      <Grid container spacing={2} sx={{ mb: 1 }}>
                        <Grid item xs={6}>
                          <Fields>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                              <Grid item xs={6}>
                                <TextField fullWidth name="firstName" label="First name" />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField fullWidth name="lastName" label="Last name" />
                              </Grid>
                            </Grid>

                            <TextField name="jobTitle" label="Job title" />

                            <TextField name="publication" label="Publication" />

                            <SelectField
                              name="country"
                              label="Country"
                              options={countries.map(item => ({
                                label: item.label,
                                value: item.label
                              }))}
                            />

                            <TextField name="email" label="Email" />

                            <TextField name="phone" label="Telephone" />
                          </Fields>
                        </Grid>
                      </Grid>

                      <Box display="flex" my={2}>
                        <SubmitButton color="primary" variant="contained" size="medium">
                          Save
                        </SubmitButton>
                        <Box ml={2}>
                          <LinkButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            to={routing.journalists.detail(data.journalistOne.id)}
                          >
                            Cancel
                          </LinkButton>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={8}>
                <DangerZone description="Once you delete account, there is no going back. Please be certain.">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      deleteJournalist();
                    }}
                  >
                    Delete user
                  </Button>
                </DangerZone>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  jobTitle: yup.string().trim(),
  publication: yup.string().trim(),
  country: yup.string(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim(),
  phone: yup.string().trim(),
  callTemplate: yup.mixed().when('activeCallTemplate', {
    is: true,
    then: yup.string().required('Call response template is a a required field.')
  }),
  emailTemplate: yup.mixed().when('activeEmailTemplate', {
    is: true,
    then: yup.string().required('Email response template is a a required field.')
  }),
  thankTemplate: yup.mixed().when('activeThankTemplate', {
    is: true,
    then: yup.string().required('Thanks response template is a a required field.')
  }),
  activeCallTemplate: yup.boolean(),
  activeEmailTemplate: yup.boolean(),
  activeThankTemplate: yup.boolean(),
  file: yup.string().nullable()
});

const query = gql<JournalistOneEditGQL>`
  query JournalistOneEdit($id: ID!) {
    journalistOne(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

const editJournalist = gql<EditJournalistGQL>`
  mutation EditJournalist($input: EditJournalistInput!) {
    editJournalist(input: $input) {
      ... on Journalist {
        id
        firstName
        lastName
        fullName
        jobTitle
        publication
        email
        country
        phone
        callTemplate
        emailTemplate
        thankTemplate
        activeCallTemplate
        activeEmailTemplate
        activeThankTemplate
      }
    }
  }
`;

const deleteMutation = gql<DeleteJournalistGQL>`
  mutation DeleteJournalist($id: ID!) {
    deleteJournalist(id: $id)
  }
`;

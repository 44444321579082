import { environment } from './environment';

export interface FileJetFile {
  readonly id: string;
  readonly mutation?: string | null;
}

export interface ExternalFileJetFile {
  readonly src: string;
  readonly mutation?: string;
}

export function mutate<T extends FileJetFile | ExternalFileJetFile>(
  file: T,
  ...mutations: string[]
): T {
  const newMutation = [...(file.mutation ?? '').split(','), ...mutations.flatMap(m => m.split(','))]
    .map(mutator => mutator.trim())
    .filter(mutator => mutator != null && mutator !== '')
    .filter(
      (mutator, index, all) => !oneTimeMutators.includes(mutator) || index === all.indexOf(mutator)
    )
    .join(',');

  return { ...file, mutation: newMutation };
}

export function isFileJetFile(value: FileJetFile | ExternalFileJetFile): value is FileJetFile {
  return (value as FileJetFile).id !== undefined;
}

export function isExternalFileJetFile(
  value: FileJetFile | ExternalFileJetFile
): value is ExternalFileJetFile {
  return (value as ExternalFileJetFile).src !== undefined;
}

export function hasNoMutation(file: FileJetFile | ExternalFileJetFile): boolean {
  return file.mutation == null || file.mutation.trim() === '';
}

/**
 * File mutators which don't need to be in mutation more than once.
 */
const oneTimeMutators = ['no_ao', 'auto'];

export interface FileUrlProps {
  readonly file: FileJetFile;
  readonly mutation?: string;
}

export function toFileUrl({ file, mutation }: FileUrlProps): string {
  const mutated = mutate(file, mutation ?? '', 'auto');

  return `${environment.imageUrl}/${mutated.id}/${mutated.mutation}`;
}

import { gql, useQuery } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { Card, CardContent, EditButton, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Call as CallIcon, Email, PanTool } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Initials } from '../../components/Initials';
import { StatusBadge } from '../../components/StatusBadge';
import { toFileUrl } from '../../file';
import { journalistFragment } from '../../fragments/journalist';
import { routing } from '../../Routes';
import { VoiceNoteList } from './VoiceNote/VoiceNoteList';

export function DetailJournalist() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { id } });

  const [resendInvite] = useFlashMutation(resendInviteMutation, {
    variables: { id },
    successMsg: 'Invitation resend successful!',
    errorMsg: 'User could not be invited!'
  });

  const [suspend] = useFlashMutation(suspendMutation, {
    variables: { id },
    successMsg: 'Journalist is suspended!',
    errorMsg: 'Something went wrong!'
  });

  const [activate] = useFlashMutation(activateMutation, {
    variables: { id },
    successMsg: 'Journalist is activated!',
    errorMsg: 'Something went wrong!'
  });

  const [invite] = useFlashMutation(inviteRequested, {
    variables: { id },
    successMsg: 'Journalist was successfully invited!',
    errorMsg: error => error.message
  });

  return (
    <Page
      title={data?.journalistOne.fullName}
      breadcrumbs={[{ label: 'Journalists', to: routing.journalists.all }, { label: `Detail` }]}
      action={
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={2}>
            {data?.journalistOne.status === 'requested' && (
              <Button color="primary" variant="contained" onClick={() => invite()}>
                Send Invitation
              </Button>
            )}

            {data?.journalistOne.status === 'invited' && (
              <Button color="primary" variant="contained" onClick={() => resendInvite()}>
                Resend invite email
              </Button>
            )}

            {data?.journalistOne.status === 'activated' && (
              <Button color="primary" variant="contained" onClick={() => suspend()}>
                Suspend account
              </Button>
            )}

            {data?.journalistOne.status === 'suspended' && (
              <Button color="primary" variant="contained" onClick={() => activate()}>
                Activate account
              </Button>
            )}
          </Box>
          {data?.journalistOne.status != null && (
            <StatusBadge status={data?.journalistOne.status} />
          )}
        </Box>
      }
    >
      <Card sx={{ borderTop: 'unset' }}>
        {populated(data) && (
          <CardContent>
            <Card title="General info">
              <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={10}>
                  <Stack direction="row" spacing={10} alignItems="center">
                    {data.journalistOne.fileIdentifier ? (
                      <img
                        style={{
                          borderRadius: '50%'
                        }}
                        width={100}
                        height={100}
                        src={
                          data.journalistOne.fileIdentifier
                            ? toFileUrl({
                                file: {
                                  id: data.journalistOne.fileIdentifier,
                                  mutation: `resize_400x400min,crop_400x400`
                                }
                              })
                            : undefined
                        }
                        alt="Profile picture"
                      />
                    ) : (
                      <Initials fullName={data.journalistOne.fullName} />
                    )}
                    <Stack direction="column">
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Name{' '}
                        </Typography>
                        : {data.journalistOne.fullName}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Job title{' '}
                        </Typography>
                        : {data.journalistOne.jobTitle}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Publication{' '}
                        </Typography>
                        : {data.journalistOne.publication}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Email{' '}
                        </Typography>
                        : {data.journalistOne.email}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Telephone{' '}
                        </Typography>
                        : {data.journalistOne.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack alignItems="center" direction="column" justifyContent="center">
                    <CallIcon style={{ fontSize: '35px' }} />
                    {data.journalistOne.activeCallTemplate ? (
                      <CheckIcon sx={{ color: 'success.light' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'error.main' }} />
                    )}
                  </Stack>
                  <Stack alignItems="center" direction="column" justifyContent="center">
                    <Email style={{ fontSize: '35px' }} />
                    {data.journalistOne.activeEmailTemplate ? (
                      <CheckIcon sx={{ color: 'success.light' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'error.main' }} />
                    )}
                  </Stack>
                  <Stack alignItems="center" direction="column" justifyContent="center">
                    <PanTool style={{ fontSize: '35px' }} />
                    {data.journalistOne.activeThankTemplate ? (
                      <CheckIcon sx={{ color: 'success.light' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'error.main' }} />
                    )}
                  </Stack>
                  <Stack direction="column" justifyContent="flex-end">
                    <EditButton
                      onClick={() => navigate(routing.journalists.edit(data.journalistOne.id))}
                    />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        )}
        <CardContent>
          <VoiceNoteList journalistId={id} />
        </CardContent>
      </Card>
    </Page>
  );
}

const query = gql<JournalistOneDetailGQL>`
  query JournalistOneDetail($id: ID!) {
    journalistOne(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

const inviteRequested = gql<InviteRequestedJournalistGQL>`
  mutation InviteRequestedJournalist($id: ID!) {
    inviteRequestedJournalist(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

const resendInviteMutation = gql<ResendJournalistInviteGQL>`
  mutation ResendJournalistInvite($id: ID!) {
    resendJournalistInvite(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

const suspendMutation = gql<SuspendJournalistGQL>`
  mutation SuspendJournalist($id: ID!) {
    suspendJournalist(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

const activateMutation = gql<ActivateJournalistGQL>`
  mutation ActivateJournalist($id: ID!) {
    activateJournalist(id: $id) {
      ...JournalistFragment
    }
  }
  ${journalistFragment}
`;

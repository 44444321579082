import { gql } from '@apollo/client';
import { Card, CardContent, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { createFormSpec, Fields, SubmitButton, TextField } from '@everlutionsk/ui-formik';
import { LinkButton, useNavigate } from '@everlutionsk/ui-router';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { routing } from '../../Routes';

export function AddAdmin() {
  const navigate = useNavigate();

  const [mutate] = useFlashMutation(mutation, {
    successMsg: 'Invite email was successfully sent!',
    errorMsg: 'An account with the given email already exist!',
    onCompleted: () => navigate(routing.admins.all)
  });

  return (
    <Page
      title="Add Admin user"
      breadcrumbs={[{ label: 'Admins', to: routing.admins.all }, { label: `Add` }]}
    >
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Formik
                {...formSpec({
                  firstName: '',
                  lastName: '',
                  email: ''
                })}
                onSubmit={({ firstName, lastName, email }) =>
                  mutate({
                    variables: {
                      input: {
                        firstName,
                        lastName,
                        email
                      }
                    }
                  })
                }
              >
                <Form>
                  <Fields>
                    <TextField fullWidth name="firstName" label="First name" />

                    <TextField fullWidth name="lastName" label="Last name" />

                    <TextField fullWidth name="email" label="Email" />
                  </Fields>

                  <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" justifyContent="flex-start" my={2}>
                      <SubmitButton color="primary" variant="contained" size="medium">
                        Send Invite
                      </SubmitButton>
                      <Box ml={2}>
                        <LinkButton
                          color="primary"
                          variant="outlined"
                          size="medium"
                          to={routing.admins.all}
                        >
                          Cancel
                        </LinkButton>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Formik>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

const formSpec = createFormSpec({
  firstName: yup.string().required("Please enter the user's first name").trim(),
  lastName: yup.string().required("Please enter the user's last name").trim(),
  email: yup
    .string()
    .email('Email must be a valid email')
    .required("Please enter the user's e-mail")
    .trim()
});

const mutation = gql<AddAdminGQL>`
  mutation AddAdmin($input: InviteAdminInput!) {
    inviteAdmin(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;

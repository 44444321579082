/* eslint-disable */

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT EDIT THIS FILE!
//
// GENERATED BY @everlutionsk/graphql-types-generator package!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Void: void;
  DateTime: string;
  JSONObject: any;
};

export type VoiceNoteManyInput = {
  pagination: PaginationInput;
  status?: Maybe<VoiceNoteStatus>;
  journalistId?: Maybe<Scalars['ID']>;
  managerId?: Maybe<Scalars['ID']>;
  favorite?: Maybe<Scalars['Boolean']>;
  hideExpired?: Maybe<Scalars['Boolean']>;
  currentManagerReacted?: Maybe<Scalars['Boolean']>;
  notRelevant?: Maybe<Scalars['Boolean']>;
};

export type ManagerManyInput = {
  pagination: PaginationInput;
  term?: Maybe<Scalars['String']>;
  status?: Maybe<Array<UserStatus>>;
  licence?: Maybe<Licence>;
};

export type JournalistManyInput = {
  pagination: PaginationInput;
  term?: Maybe<Scalars['String']>;
  status?: Maybe<Array<UserStatus>>;
};

export type AdminManyInput = {
  pagination: PaginationInput;
};

export type EditVoiceNoteInput = {
  id: Scalars['ID'];
  deadline: Scalars['DateTime'];
};

export type EditVoiceNoteAdminInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  deadline: Scalars['DateTime'];
  locations?: Maybe<Array<Scalars['String']>>;
};

export type CreateVoiceNoteInput = {
  title: Scalars['String'];
  fileIdentifier: Scalars['String'];
  deadline: Scalars['DateTime'];
  locations: Array<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platformVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
};

export type CreateReplyInput = {
  voiceNoteId: Scalars['ID'];
  fileIdentifier: Scalars['String'];
  platform?: Maybe<Scalars['String']>;
  platformVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
};

export type CreateAnswerToReplyInput = {
  replyId: Scalars['ID'];
  voiceNoteId: Scalars['ID'];
  fileIdentifier?: Maybe<Scalars['String']>;
  type: AnswerType;
  platform?: Maybe<Scalars['String']>;
  platformVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
};

export type CreateAccountInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export const Role = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  journalist: 'journalist',
  pr: 'pr'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type SignInInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type RefreshIdentityInput = {
  refreshToken: Scalars['String'];
};

export type FileUploadRequest = {
  name: Scalars['String'];
  type: Scalars['String'];
  access?: Maybe<FileAccess>;
};

export const FileAccess = {
  public: 'public',
  private: 'private'
} as const;

export type FileAccess = typeof FileAccess[keyof typeof FileAccess];

export const UserStatus = {
  activated: 'activated',
  suspended: 'suspended',
  invited: 'invited',
  requested: 'requested',
  deleted: 'deleted'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];
export type InviteJournalistInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  publication?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type RequestJournalistInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  publication: Scalars['String'];
  email: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  country: Scalars['String'];
};

export type AcceptInvitationInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type EditJournalistInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  publication?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  callTemplate?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<Scalars['String']>;
  thankTemplate?: Maybe<Scalars['String']>;
  activeCallTemplate?: Maybe<Scalars['Boolean']>;
  activeEmailTemplate?: Maybe<Scalars['Boolean']>;
  activeThankTemplate?: Maybe<Scalars['Boolean']>;
  confirmedDetails?: Maybe<Scalars['Boolean']>;
  setupAutoReplies?: Maybe<Scalars['Boolean']>;
  fileIdentifier?: Maybe<Scalars['String']>;
  showEndedModal?: Maybe<Scalars['Boolean']>;
};

export type InviteManagerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  licence: Licence;
  licenceExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type RequestManagerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export const Licence = {
  full: 'full',
  trial: 'trial'
} as const;

export type Licence = typeof Licence[keyof typeof Licence];
export type EditManagerInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  showEmail?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  showPhone?: Maybe<Scalars['Boolean']>;
  licence?: Maybe<Licence>;
  status?: Maybe<UserStatus>;
  confirmedDetails?: Maybe<Scalars['Boolean']>;
  fileIdentifier?: Maybe<Scalars['String']>;
  licenceExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type ChangePasswordInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export const VoiceNoteStatus = {
  active: 'active',
  archived: 'archived',
  deleted: 'deleted'
} as const;

export type VoiceNoteStatus = typeof VoiceNoteStatus[keyof typeof VoiceNoteStatus];

export type ReplyManyInput = {
  pagination: PaginationInput;
  voiceNoteId?: Maybe<Scalars['ID']>;
  managerId?: Maybe<Scalars['ID']>;
  answered?: Maybe<Scalars['Boolean']>;
  hideSpam?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export const AnswerType = {
  audio: 'audio',
  call: 'call',
  email: 'email',
  thankYou: 'thankYou'
} as const;

export type AnswerType = typeof AnswerType[keyof typeof AnswerType];
export type PaginationInput = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  fromKey?: Maybe<Scalars['ID']>;
};

export type InviteAdminInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type CompleteResetPasswordInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type EditAdminInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UploadInstructionInput = {
  file: FileUploadRequest;
  waveForm?: Maybe<Array<Scalars['Int']>>;
  bucket: BucketType;
};

export const BucketType = {
  dataStorage: 'dataStorage',
  imageStorage: 'imageStorage'
} as const;

export type BucketType = typeof BucketType[keyof typeof BucketType];

export type UploadInstructionManyQueryVariables = Exact<{
  input: Array<UploadInstructionInput> | UploadInstructionInput;
}>;

export type UploadInstructionManyQuery = {
  uploadInstructionMany: Array<{
    __typename: 'UploadInstruction';
    id: string;
    uploadFormat: { __typename: 'UploadFormat'; url: string; httpMethod: string; headers: any };
  }>;
};

export type JournalistFragment = {
  __typename: 'Journalist';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle?: Maybe<string>;
  publication?: Maybe<string>;
  phone?: Maybe<string>;
  country?: Maybe<string>;
  status: UserStatus;
  callTemplate?: Maybe<string>;
  emailTemplate?: Maybe<string>;
  thankTemplate?: Maybe<string>;
  activeCallTemplate: boolean;
  activeEmailTemplate: boolean;
  activeThankTemplate: boolean;
  fileIdentifier?: Maybe<string>;
};

export type ManagerFragment = {
  __typename: 'Manager';
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle?: Maybe<string>;
  company?: Maybe<string>;
  brand?: Maybe<string>;
  country?: Maybe<string>;
  phone?: Maybe<string>;
  email: string;
  showPhone: boolean;
  showEmail: boolean;
  licence: Licence;
  status: UserStatus;
  invitedAt?: Maybe<string>;
  lastActiveAt?: Maybe<string>;
  fileIdentifier?: Maybe<string>;
  licenceExpiredAt?: Maybe<string>;
};

export type AddAdminMutationVariables = Exact<{
  input: InviteAdminInput;
}>;

export type AddAdminMutation = {
  inviteAdmin: {
    __typename: 'Admin';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type AdminManyQueryVariables = Exact<{
  input: AdminManyInput;
}>;

export type AdminManyQuery = {
  adminMany: {
    __typename: 'AdminPage';
    total: number;
    items: Array<{
      __typename: 'Admin';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      createdAt: string;
      firstLoginAt?: Maybe<string>;
      lastActiveAt?: Maybe<string>;
    }>;
  };
};

export type AdminOneEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AdminOneEditQuery = {
  adminOne: {
    __typename: 'Admin';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: UserStatus;
    invitedAt: string;
    lastActiveAt?: Maybe<string>;
  };
};

export type ResendAdminInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResendAdminInviteMutation = {
  resendAdminInvite: { __typename: 'Admin'; id: string; invitedAt: string };
};

export type EditAdminMutationVariables = Exact<{
  input: EditAdminInput;
}>;

export type EditAdminMutation = {
  editAdmin: {
    __typename: 'Admin';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type AddJournalistMutationVariables = Exact<{
  input: InviteJournalistInput;
}>;

export type AddJournalistMutation = {
  inviteJournalist: {
    __typename: 'Journalist';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    jobTitle?: Maybe<string>;
    phone?: Maybe<string>;
    country?: Maybe<string>;
    status: UserStatus;
  };
};

export type JournalistOneDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type JournalistOneDetailQuery = {
  journalistOne: { __typename: 'Journalist' } & JournalistFragment;
};

export type InviteRequestedJournalistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InviteRequestedJournalistMutation = {
  inviteRequestedJournalist: { __typename: 'Journalist' } & JournalistFragment;
};

export type ResendJournalistInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResendJournalistInviteMutation = {
  resendJournalistInvite: { __typename: 'Journalist' } & JournalistFragment;
};

export type SuspendJournalistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SuspendJournalistMutation = {
  suspendJournalist: { __typename: 'Journalist' } & JournalistFragment;
};

export type ActivateJournalistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActivateJournalistMutation = {
  activateJournalist: { __typename: 'Journalist' } & JournalistFragment;
};

export type JournalistOneEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type JournalistOneEditQuery = {
  journalistOne: { __typename: 'Journalist' } & JournalistFragment;
};

export type EditJournalistMutationVariables = Exact<{
  input: EditJournalistInput;
}>;

export type EditJournalistMutation = {
  editJournalist:
    | {
        __typename: 'Journalist';
        id: string;
        firstName: string;
        lastName: string;
        fullName: string;
        jobTitle?: Maybe<string>;
        publication?: Maybe<string>;
        email: string;
        country?: Maybe<string>;
        phone?: Maybe<string>;
        callTemplate?: Maybe<string>;
        emailTemplate?: Maybe<string>;
        thankTemplate?: Maybe<string>;
        activeCallTemplate: boolean;
        activeEmailTemplate: boolean;
        activeThankTemplate: boolean;
      }
    | { __typename: 'Manager' }
    | { __typename: 'Admin' };
};

export type DeleteJournalistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteJournalistMutation = { deleteJournalist?: Maybe<void> };

export type JournalistManyQueryVariables = Exact<{
  input: JournalistManyInput;
  inputVoiceNoteMany: VoiceNoteManyInput;
}>;

export type JournalistManyQuery = {
  journalistMany: {
    __typename: 'JournalistPage';
    total: number;
    items: Array<{
      __typename: 'Journalist';
      id: string;
      fullName: string;
      publication?: Maybe<string>;
      country?: Maybe<string>;
      status: UserStatus;
      cognitoId?: Maybe<string>;
      voiceNoteMany?: Maybe<{
        __typename: 'VoiceNoteMany';
        unread: number;
        page: {
          __typename: 'VoiceNotePage';
          total: number;
          items: Array<{ __typename: 'VoiceNote'; id: string }>;
        };
      }>;
    }>;
  };
};

export type JournalistRequestedManyQueryVariables = Exact<{
  input: JournalistManyInput;
}>;

export type JournalistRequestedManyQuery = {
  journalistMany: {
    __typename: 'JournalistPage';
    total: number;
    items: Array<{
      __typename: 'Journalist';
      id: string;
      fullName: string;
      publication?: Maybe<string>;
      country?: Maybe<string>;
      status: UserStatus;
      createdAt: string;
      requestedAt?: Maybe<string>;
    }>;
  };
};

export type VoiceNoteOneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VoiceNoteOneQuery = {
  voiceNoteOne: {
    __typename: 'VoiceNote';
    id: string;
    title: string;
    deadline: string;
    createdAt: string;
    locations: Array<string>;
    audioFile: { __typename: 'AudioFile'; identifier: string; uri: string };
    journalist: { __typename: 'Journalist'; id: string; fullName: string };
  };
};

export type EditVoiceNoteAdminMutationVariables = Exact<{
  input: EditVoiceNoteAdminInput;
}>;

export type EditVoiceNoteAdminMutation = {
  editVoiceNoteAdmin: {
    __typename: 'VoiceNote';
    id: string;
    title: string;
    deadline: string;
    createdAt: string;
    locations: Array<string>;
    audioFile: { __typename: 'AudioFile'; identifier: string; uri: string };
    journalist: { __typename: 'Journalist'; id: string; fullName: string };
  };
};

export type DeleteVoiceNoteAdminMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteVoiceNoteAdminMutation = { deleteVoiceNoteAdmin?: Maybe<void> };

export type RepliesManyQueryVariables = Exact<{
  input: ReplyManyInput;
}>;

export type RepliesManyQuery = {
  replyMany: {
    __typename: 'ReplyMany';
    unread: number;
    page: {
      __typename: 'ReplyPage';
      total: number;
      items: Array<{
        __typename: 'Reply';
        id: string;
        createdAt: string;
        unread: boolean;
        listened: boolean;
        flagged: boolean;
        platform?: Maybe<string>;
        platformVersion?: Maybe<string>;
        appVersion?: Maybe<string>;
        audioFile: { __typename: 'AudioFile'; identifier: string; uri: string };
        answer?: Maybe<{
          __typename: 'Answer';
          text?: Maybe<string>;
          type: AnswerType;
          createdAt: string;
          platform?: Maybe<string>;
          platformVersion?: Maybe<string>;
          appVersion?: Maybe<string>;
          audioFile?: Maybe<{ __typename: 'AudioFile'; identifier: string; uri: string }>;
        }>;
        manager: { __typename: 'Manager'; id: string; fullName: string };
      }>;
    };
  };
};

export type DeleteReplyAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  voiceNoteId: Scalars['ID'];
}>;

export type DeleteReplyAdminMutation = { deleteReplyAdmin?: Maybe<void> };

export type VoiceNoteManyQueryVariables = Exact<{
  input: VoiceNoteManyInput;
}>;

export type VoiceNoteManyQuery = {
  voiceNoteMany: {
    __typename: 'VoiceNoteMany';
    unread: number;
    page: {
      __typename: 'VoiceNotePage';
      total: number;
      items: Array<{
        __typename: 'VoiceNote';
        id: string;
        title: string;
        deadline: string;
        locations: Array<string>;
        createdAt: string;
        platform?: Maybe<string>;
        platformVersion?: Maybe<string>;
        appVersion?: Maybe<string>;
        replyMany: {
          __typename: 'ReplyMany';
          unread: number;
          page: {
            __typename: 'ReplyPage';
            total: number;
            items: Array<{ __typename: 'Reply'; id: string }>;
          };
        };
      }>;
    };
  };
};

export type AddManagerMutationVariables = Exact<{
  input: InviteManagerInput;
}>;

export type AddManagerMutation = {
  inviteManager: {
    __typename: 'Manager';
    id: string;
    firstName: string;
    lastName: string;
    jobTitle?: Maybe<string>;
    company?: Maybe<string>;
    country?: Maybe<string>;
    email: string;
    phone?: Maybe<string>;
    licenceExpiredAt?: Maybe<string>;
  };
};

export type ManagerOneDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ManagerOneDetailQuery = { managerOne: { __typename: 'Manager' } & ManagerFragment };

export type InviteRequestedManagerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InviteRequestedManagerMutation = {
  inviteRequestedManager: { __typename: 'Manager' } & ManagerFragment;
};

export type ResendManagerInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResendManagerInviteMutation = {
  resendManagerInvite: { __typename: 'Manager' } & ManagerFragment;
};

export type SuspendManagerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SuspendManagerMutation = {
  suspendManager: { __typename: 'Manager' } & ManagerFragment;
};

export type ActivateManagerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActivateManagerMutation = {
  activateManager: { __typename: 'Manager' } & ManagerFragment;
};

export type EditManagerMutationVariables = Exact<{
  input: EditManagerInput;
}>;

export type EditManagerMutation = {
  editManager:
    | { __typename: 'Journalist' }
    | {
        __typename: 'Manager';
        id: string;
        firstName: string;
        lastName: string;
        fullName: string;
        jobTitle?: Maybe<string>;
        company?: Maybe<string>;
        brand?: Maybe<string>;
        country?: Maybe<string>;
        phone?: Maybe<string>;
        email: string;
        showPhone: boolean;
        showEmail: boolean;
        licence: Licence;
        status: UserStatus;
        invitedAt?: Maybe<string>;
        fileIdentifier?: Maybe<string>;
      }
    | { __typename: 'Admin' };
};

export type DeleteManagerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteManagerMutation = { deleteManager?: Maybe<void> };

export type ManagerOneEditProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ManagerOneEditProfileQuery = {
  managerOne: { __typename: 'Manager' } & ManagerFragment;
};

export type ManagerManyQueryVariables = Exact<{
  input: ManagerManyInput;
}>;

export type ManagerManyQuery = {
  managerMany: {
    __typename: 'ManagerPage';
    total: number;
    items: Array<{
      __typename: 'Manager';
      id: string;
      fullName: string;
      company?: Maybe<string>;
      country?: Maybe<string>;
      status: UserStatus;
      licence: Licence;
      lastActiveAt?: Maybe<string>;
      cognitoId?: Maybe<string>;
    }>;
  };
};

export type NotRelevantListQueryVariables = Exact<{
  input: VoiceNoteManyInput;
  id: Scalars['ID'];
}>;

export type NotRelevantListQuery = {
  voiceNoteMany: {
    __typename: 'VoiceNoteMany';
    unread: number;
    page: {
      __typename: 'VoiceNotePage';
      total: number;
      items: Array<{
        __typename: 'VoiceNote';
        id: string;
        title: string;
        managerReactedAt?: Maybe<string>;
        journalist: { __typename: 'Journalist'; id: string; fullName: string };
      }>;
    };
  };
};

export type ReEnableVoiceNoteMutationVariables = Exact<{
  managerId: Scalars['ID'];
  voiceNoteId: Scalars['ID'];
}>;

export type ReEnableVoiceNoteMutation = { reEnableVoiceNote?: Maybe<void> };

export type ManagerRepliesManyQueryVariables = Exact<{
  input: ReplyManyInput;
}>;

export type ManagerRepliesManyQuery = {
  replyMany: {
    __typename: 'ReplyMany';
    unread: number;
    page: {
      __typename: 'ReplyPage';
      total: number;
      items: Array<{
        __typename: 'Reply';
        id: string;
        createdAt: string;
        unread: boolean;
        listened: boolean;
        flagged: boolean;
        platform?: Maybe<string>;
        platformVersion?: Maybe<string>;
        appVersion?: Maybe<string>;
        voiceNote: {
          __typename: 'VoiceNote';
          id: string;
          platform?: Maybe<string>;
          platformVersion?: Maybe<string>;
          appVersion?: Maybe<string>;
          audioFile: { __typename: 'AudioFile'; identifier: string; uri: string };
          journalist: { __typename: 'Journalist'; id: string; fullName: string };
        };
        audioFile: { __typename: 'AudioFile'; identifier: string; uri: string };
        answer?: Maybe<{
          __typename: 'Answer';
          text?: Maybe<string>;
          type: AnswerType;
          createdAt: string;
          platform?: Maybe<string>;
          platformVersion?: Maybe<string>;
          appVersion?: Maybe<string>;
          audioFile?: Maybe<{ __typename: 'AudioFile'; identifier: string; uri: string }>;
        }>;
      }>;
    };
  };
};

export type DeleteManagerReplyAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  voiceNoteId: Scalars['ID'];
}>;

export type DeleteManagerReplyAdminMutation = { deleteReplyAdmin?: Maybe<void> };

export type ManagerRequestedManyQueryVariables = Exact<{
  input: ManagerManyInput;
}>;

export type ManagerRequestedManyQuery = {
  managerMany: {
    __typename: 'ManagerPage';
    total: number;
    items: Array<{
      __typename: 'Manager';
      id: string;
      fullName: string;
      company?: Maybe<string>;
      country?: Maybe<string>;
      status: UserStatus;
      licence: Licence;
      lastActiveAt?: Maybe<string>;
      requestedAt?: Maybe<string>;
    }>;
  };
};

export type ManagerTrialManyQueryVariables = Exact<{
  input: ManagerManyInput;
}>;

export type ManagerTrialManyQuery = {
  managerMany: {
    __typename: 'ManagerPage';
    total: number;
    items: Array<{
      __typename: 'Manager';
      id: string;
      fullName: string;
      company?: Maybe<string>;
      country?: Maybe<string>;
      status: UserStatus;
      licence: Licence;
      lastActiveAt?: Maybe<string>;
      requestedAt?: Maybe<string>;
      licenceExpiredAt?: Maybe<string>;
      cognitoId?: Maybe<string>;
    }>;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetPasswordMutation = { resetPassword?: Maybe<void> };

export type UpdateLastActiveAtAdminMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateLastActiveAtAdminMutation = { updateLastActiveAt?: Maybe<void> };

export type AdminOneAcceptInvitationQueryVariables = Exact<{
  token: Scalars['ID'];
}>;

export type AdminOneAcceptInvitationQuery = {
  adminOneConfirmation: { __typename: 'Admin'; id: string; firstName: string; email: string };
};

export type AcceptAdminInvitationMutationVariables = Exact<{
  input: AcceptInvitationInput;
}>;

export type AcceptAdminInvitationMutation = {
  acceptAdminInvitation: { __typename: 'Admin'; id: string };
};

export type UpdateLastActiveAfterAdminAcceptInvitationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UpdateLastActiveAfterAdminAcceptInvitationMutation = {
  updateLastActiveAt?: Maybe<void>;
};

export type CompleteResetPasswordMutationVariables = Exact<{
  input: CompleteResetPasswordInput;
}>;

export type CompleteResetPasswordMutation = {
  completeResetPassword: { __typename: 'Admin'; id: string };
};

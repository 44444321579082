import { gql, useApolloClient } from '@apollo/client';
import { fileToSrc, mutate, useFileJet, useUploader } from '@everlutionsk/filejet-react';
import { LoadingOverlay } from '@everlutionsk/ui';
import { Fab, Fade, IconButton, Paper } from '@mui/material';
import { AddCircle, Delete } from '@mui/icons-material';
import { Field, FieldProps } from 'formik';
import React, { memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { BucketType } from '../graphql/types';

export interface ImageFieldProps {
  readonly label: ReactNode;
  readonly name: string;
  readonly width: number;
  readonly height: number;
}

export function ImageField(props: ImageFieldProps) {
  return <Field name={props.name}>{fieldProps => <Control {...fieldProps} {...props} />}</Field>;
}

function Control(props: ImageFieldProps & FieldProps) {
  const apollo = useApolloClient();
  const [hover, setHover] = useState(false);
  const [lastUploadedFileId, setLastUploadedFileId] = useState<string | undefined>();

  const { width, height } = props;
  const paddingX = 5;
  const paddingY = 5;

  const { value } = props.field;

  const uploader = useUploader({
    accept: ['image/*'],
    maxFiles: 1,
    uploadInstructions: async files => {
      const result = await apollo.query({
        query,
        variables: {
          input: files.map(({ name, type }) => {
            return {
              bucket: BucketType.imageStorage,
              file: { name, type }
            };
          })
        }
      });

      const error = result.error ?? result.errors;
      if (error) throw error;

      return result.data.uploadInstructionMany as any;
    }
  });

  // When the field value is changed externally,
  // we need to remove the already uploaded file.
  useEffect(() => {
    if (value != null && lastUploadedFileId != null && value.id !== lastUploadedFileId) {
      uploader.removeAll();
      setLocalFileSrc(undefined);
    }
  }, [lastUploadedFileId, value]);

  const uploading = uploader.entries[0]?.state === 'uploading';
  const file = uploader.entries[0]?.file;

  const [localFileSrc, setLocalFileSrc] = useState<string | undefined>();

  useEffect(() => {
    if (file != null) {
      fileToSrc(file).then(setLocalFileSrc);
    }
  }, [file]);

  const mutation = useMemo(() => {
    function scale(value: number) {
      const w = Math.round(width * value);
      const h = Math.round(height * value);
      return `resize_${w}x${h}shrink`;
    }

    // todo: reduce quality in high dpi mode as it is not visible by eye
    return {
      '1x': scale(1),
      '1.5x': scale(1.5),
      '2x': scale(2),
      '2.5x': scale(2.5),
      '3x': scale(3),
      '3.5x': scale(3.5),
      '4x': scale(4)
    };
  }, [width, height]);

  const image = localFileSrc ?? value;

  return (
    <div>
      <Paper
        variant="outlined"
        style={{
          display: 'flex',
          width: width + paddingX,
          height: height + paddingY,
          justifyContent: 'center',
          alignItems: 'center',
          padding: `${paddingY}px ${paddingX}px`,
          position: 'relative'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="MuiFormLabel-root"
          style={{
            fontSize: '12px',
            pointerEvents: 'none',
            position: 'absolute',
            top: '-7px',
            left: '8px',
            backgroundColor: 'white',
            padding: '0 4px'
          }}
        >
          {props.label}
        </div>

        {/* TODO: Whole field area should be a clickable dropzone */}
        {image == null && (
          <Fade in>
            <IconButton
              color="primary"
              aria-label="add"
              onClick={async () => {
                const [selected] = await uploader.select({ replace: true });
                if (selected == null) return;

                const [[result]] = await Promise.all([
                  // todo: we need to hold the formik submit process until the image is uploaded
                  uploader.upload(),
                  fileToSrc(selected.file)
                ]);

                if (result.success) {
                  const newImage: ImageData = {
                    id: result.entry.remoteId
                  };
                  setLastUploadedFileId(newImage.id);
                  props.form.setFieldValue(props.field.name, newImage);
                }
              }}
            >
              <AddCircle />
            </IconButton>
          </Fade>
        )}

        {image && (
          <div style={{ position: 'absolute' }}>
            <LoadingOverlay loading={uploading}>
              <Img
                image={image}
                alt={typeof props.label === 'string' ? props.label : 'image'}
                width={width}
                height={height}
                mutation={mutation}
              />
            </LoadingOverlay>
          </div>
        )}

        {image && hover && (
          <Fade in>
            <Fab
              size="small"
              aria-label="delete"
              onClick={() => {
                uploader.removeAll();
                setLocalFileSrc(undefined);
                props.form.setFieldValue(props.field.name, null);
              }}
            >
              <Delete />
            </Fab>
          </Fade>
        )}
      </Paper>
    </div>
  );
}

type Src = string;

const maxEncodingSize = 32;

interface ImageProps {
  readonly image: ImageData | string; // TODO: Support browser's File instance (REVOKE DATA:URL when File change)
  readonly alt: string;
  readonly mutation?: string | SrcSetMutation;
  readonly width: number;
  readonly height: number;
}

type SrcSetMutation = Record<string, string>;

interface ImageData {
  readonly id: string;
  readonly mutation?: string | null;
  readonly ratio?: number | null;
  readonly hash?: string | null;
}

// todo: allow to stretch blurhash to fit the provided width:height (to ignore ratio)
// todo: image is not resized in views - support srcset
export const Img = memo((props: ImageProps) => {
  const filejet = useFileJet();
  const ref = useRef<HTMLElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  const isRawSrc = typeof props.image === 'string';

  const srcset = useMemo(() => {
    if (isRawSrc) return props.image;
    if (props.mutation == null) return filejet.url(mutate(props.image, 'auto'));

    return Object.entries(props.mutation)
      .map(
        ([key, mutation]) =>
          `${filejet.url(mutate(props.image as ImageData, mutation, 'auto'))} ${key}`
      )
      .join(',');
  }, [props.image, props.mutation]);

  const ratio = isRawSrc ? undefined : props.image.ratio;

  // todo: not real, but scaled
  const realWidth = Math.round(
    ratio ? (ratio >= 1 ? maxEncodingSize : maxEncodingSize * ratio) : props.width
  );
  const realHeight = Math.round(
    ratio ? (ratio >= 1 ? maxEncodingSize / ratio : maxEncodingSize) : props.height
  );

  return (
    <div
      style={{
        position: 'relative',
        width: props.width,
        height: props.height
        // todo: allow pass sx from props
      }}
    >
      {/* TODO: ERROR MESSAGE WHEN LOADING FAILS */}
      <img
        srcSet={srcset}
        loading="lazy"
        decoding="async"
        alt={props.alt}
        onLoad={() => setLoaded(true)}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
    </div>
  );
});

const query = gql<UploadInstructionManyQueryGQL>`
  query UploadInstructionManyQuery($input: [UploadInstructionInput!]!) {
    uploadInstructionMany(input: $input) {
      id
      uploadFormat {
        url
        httpMethod
        headers
      }
    }
  }
`;

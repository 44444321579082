import { gql, useMutation } from '@apollo/client';
import { useCitadel } from '@everlutionsk/citadel-react';
import { SignInScreen } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { Navigate, useNavigate } from '@everlutionsk/ui-router';
import React from 'react';
import { AuthBackground } from '../../components/AuthBackground';
import logo from './logo.svg';

export function SignIn() {
  const citadel = useCitadel();
  const navigate = useNavigate();

  const [resetPassword] = useFlashMutation(resetPasswordMutation, {
    successMsg: 'Password reset was requested successfully.',
    errorMsg: error => error.message
  });

  const [updateLastActivity] = useMutation(mutation);

  if (citadel.identity != null) {
    return <Navigate to="/" />;
  }

  return (
    <AuthBackground>
      <SignInScreen
        onSignIn={async credentials => {
          await citadel.signIn(credentials);
          await updateLastActivity();
          navigate('/journalists');
        }}
        onResetPassword={async credentials => {
          await resetPassword({ variables: { email: credentials.login } });
        }}
        description={{
          signIn: 'Sign in to use Travel Notes',
          forgottenPassword: 'Reset password for Travel Notes'
        }}
        logo={logo}
        companyName="Roxhill Media"
      />
    </AuthBackground>
  );
}

const resetPasswordMutation = gql<ResetPasswordGQL>`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const mutation = gql<UpdateLastActiveAtAdminGQL>`
  mutation UpdateLastActiveAtAdmin {
    updateLastActiveAt
  }
`;

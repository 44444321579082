import { gql } from '@apollo/client';
import { Table, useSearchField } from '@everlutionsk/ui';
import { CardContent, TableTitle } from '@everlutionsk/ui-admin';
import { useOffsetPaginatedQuery } from '@everlutionsk/ui-apollo';
import { Link } from '@everlutionsk/ui-router';
import { isEmpty } from 'ramda';
import React from 'react';
import { TableRowNavigation } from '../../components/TableRowNavigation';
import { TableWrapper } from '../../components/TableWrapper';
import { UserStatus } from '../../graphql/types';
import { prettifyStatus } from '../../prettifyData';
import { routing } from '../../Routes';

export function ExistingJournalistList() {
  const [term, searchInput] = useSearchField('existingJournalistList.term');

  const { data, pagination } = useOffsetPaginatedQuery(query, {
    variables: ({ limit, offset }) => ({
      input: {
        pagination: { limit, offset },
        term,
        status: [UserStatus.activated, UserStatus.invited, UserStatus.suspended]
      },
      inputVoiceNoteMany: {
        pagination: { limit: 1, offset: 0 }
      }
    })
  });

  return (
    <>
      <CardContent>{searchInput}</CardContent>
      <CardContent>
        <TableTitle
          total={data?.journalistMany.total}
          title="Existing Journalist users"
          totalNumberDescription="results matching your search"
        />
        <TableWrapper>
          <Table
            items={data?.journalistMany.items}
            pagination={pagination}
            columns={[
              {
                label: 'Name',
                render: ({ fullName, id }) => (
                  <Link to={routing.journalists.detail(id)}>{fullName}</Link>
                )
              },
              {
                label: 'Publication',
                render: ({ publication }) =>
                  publication != null && !isEmpty(publication) ? publication : '-'
              },
              {
                label: 'Country',
                render: ({ country }) => (country != null && !isEmpty(country) ? country : '-')
              },
              {
                label: 'VNs',
                render: item => item.voiceNoteMany?.page.total ?? 0
              },
              {
                label: 'Status',
                render: ({ status }) => prettifyStatus(status)
              },
              {
                label: 'Cognito ID',
                render: ({ cognitoId }) => cognitoId ?? '-'
              },
              {
                align: 'right',
                label: '',
                render: ({ id }) => <TableRowNavigation to={routing.journalists.detail(id)} />
              }
            ]}
          />
        </TableWrapper>
      </CardContent>
    </>
  );
}

const query = gql<JournalistManyGQL>`
  query JournalistMany($input: JournalistManyInput!, $inputVoiceNoteMany: VoiceNoteManyInput!) {
    journalistMany(input: $input) {
      total
      items {
        id
        fullName
        publication
        country
        status
        cognitoId
        voiceNoteMany(input: $inputVoiceNoteMany) {
          unread
          page {
            total
            items {
              id
            }
          }
        }
      }
    }
  }
`;

import { useIdentity } from '@everlutionsk/citadel-react';
import { Centered } from '@everlutionsk/ui';
import { Navigate, Route, RouteProps } from '@everlutionsk/ui-router';
import { Typography } from '@mui/material';
import React from 'react';

export function AuthenticatedRoute(props: RouteProps) {
  const identity = useIdentity();
  const element =
    identity?.role === 'superAdmin' || identity?.role === 'admin' ? props.element : <Forbidden />;
  return <Route {...props} element={element} />;
}

function Forbidden() {
  const identity = useIdentity();

  if (identity == null) {
    const from = encodeURIComponent(window.location.pathname);
    const to = `/auth/sign-in?from=${from}`;
    return <Navigate to={to} />;
  }

  // We renders 404 page when user's role is not what we expected.
  return (
    <Centered>
      <Typography variant="h3">Page not found!</Typography>
    </Centered>
  );
}

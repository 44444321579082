import { CitadelClient, debugCitadel } from '@everlutionsk/citadel-react';
import { FileJetClient } from '@everlutionsk/filejet-react';
import { createApolloClient } from '@everlutionsk/helpers-apollo-react';
import React from 'react';
import { environment } from './environment';
import { App } from './pages/App';
import { createRoot } from 'react-dom/client';

const { api } = environment;

const root = createRoot(document.getElementById('root')!);

const citadel = new CitadelClient({
  url: api,
  protocol: 'graphql',
  maxIdentityAge: 60 * 60 * 1000, // 60 minutes
  onSignOut: async () => {
    console.info('Clearing state!');
    await apollo.clearStore();
    window.location.reload();
  }
});

// Allows to use Citadel from browser's console.
debugCitadel(citadel);

const apollo = createApolloClient({
  url: api,
  cacheConfig: {
    // possibleTypes
  },
  authHeaders: citadel.authHeaders,
  onUnauthorized: citadel.signOut,
  batchInterval: 10
});

const filejet = new FileJetClient({
  storageId: ''
});

root.render(<App apollo={apollo} citadel={citadel} filejet={filejet} />);

import { createUiTheme, UiTheme } from '@everlutionsk/ui';
import { createUiAdminTheme, UiAdminTheme } from '@everlutionsk/ui-admin';
import { createUiApolloTheme } from '@everlutionsk/ui-apollo';
import { UiApolloTheme } from '@everlutionsk/ui-apollo/dist/esm/src/theme';
import { createUiFormikTheme, UiFormikTheme } from '@everlutionsk/ui-formik';
import { createTheme, Slide } from '@mui/material';
import React, { useMemo } from 'react';

export function hatchingBackground(color: string): string {
  return `repeating-linear-gradient( -45deg, #dddddd, #dddddd 2px, ${color} 2px, ${color} 10px )`;
}

export function useTheme() {
  const spacing = 8;

  return useMemo(() => {
    const muiTheme = createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#F8C147',
          light: '#eed192',
          dark: '#AF821E',
          contrastText: '#0E4539'
        },
        secondary: {
          main: '#4373C1'
        },
        error: {
          main: '#B60808'
        },
        warning: {
          main: '#f3830c'
        },
        info: {
          main: '#1e8ae8'
        },
        success: {
          main: '#30A76E'
        },
        background: {
          paper: '#FFF',
          default: '#F3F7F8'
        },
        text: {
          primary: '#333333',
          secondary: '#666666',
          disabled: '#B4B3B3'
        }
      },
      spacing,
      typography: {
        h1: {
          fontSize: '2.5rem',
          marginBottom: spacing * 2
        },
        h2: {
          fontSize: '1.5rem',
          marginBottom: spacing * 2
        },
        h3: {
          fontSize: '1.05rem',
          marginBottom: spacing,
          fontWeight: 700
        },
        body1: {
          fontSize: '0.9rem'
        },
        body2: {
          fontSize: '0.75rem'
        },
        caption: {
          fontSize: '0.625rem'
        }
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              background:
                'transparent linear-gradient(92deg, #085555 0%, #041E2D 100%) 0% 0% no-repeat'
            }
          }
        },
        MuiContainer: {
          defaultProps: {
            maxWidth: 'xl'
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid #F3F7F8',
              fontSize: '0.85rem'
            }
          }
        },
        MuiTable: {
          defaultProps: {
            size: 'small'
          },
          styleOverrides: {
            root: {
              border: '4px solid #F3F7F8'
            }
          }
        },
        MuiTabs: {
          defaultProps: {
            scrollButtons: true,
            allowScrollButtonsMobile: true
          },
          styleOverrides: {
            root: {
              borderBottom: '1px solid #e2e2e287'
            }
          }
        },
        MuiPaper: {
          defaultProps: {
            variant: 'outlined'
          }
        },
        MuiTextField: {
          defaultProps: {
            variant: 'outlined'
          },
          styleOverrides: {
            root: {
              backgroundColor: '#fff',
              '& .MuiFormLabel-root': {
                fontSize: '1rem'
              }
            }
          }
        },
        MuiSnackbar: {
          defaultProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            TransitionComponent: props => <Slide {...props} direction="left" />
          }
        },
        MuiSlider: {
          styleOverrides: {
            track: {
              backgroundColor: '#707070'
            },
            thumbColorPrimary: {
              color: '#F8C147'
            },
            colorPrimary: {
              color: '#707070'
            }
          }
        },
        MuiLink: {
          defaultProps: {
            underline: 'hover',
            color: 'secondary'
          },
          styleOverrides: {
            button: {
              verticalAlign: undefined,
              fontSize: 'inherit'
            },
            root: {
              cursor: 'pointer'
            }
          }
        },
        MuiDialog: {
          defaultProps: {
            maxWidth: 'xs',
            fullWidth: true
          },
          styleOverrides: {
            paper: {
              // Fix scroll issues
              '& > form': {
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }
            }
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: '0.688rem',
              color: '#6C7B84'
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: '0.9rem'
            }
          }
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              paddingTop: '6px'
            }
          }
        },
        MuiDialogContentText: {
          defaultProps: {
            marginBottom: 1
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: `${spacing * 2}px 20px`
            }
          }
        },
        MuiCheckbox: {
          defaultProps: {
            color: 'secondary'
          }
        },
        MuiSwitch: {
          defaultProps: {
            color: 'secondary'
          },
          styleOverrides: {
            track: {
              opacity: 1,
              backgroundColor: '#EF0014'
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              maxWidth: '600px'
            }
          }
        },
        MuiSvgIcon: {
          styleOverrides: {
            fontSizeSmall: {
              width: '15px',
              height: '15px',
              color: '#b1adad'
            }
          }
        },
        MuiIcon: {
          styleOverrides: {
            fontSizeSmall: {
              width: '15px',
              height: '15px',
              color: '#b1adad'
            }
          }
        }
      }
    });

    const uiTheme = createUiTheme(muiTheme, {
      SearchField: {
        defaultProps: {
          fullWidth: true
        }
      },
      Tabs: {
        indicatorColor: '#1A4E40',
        activeTextColor: '#04281f',
        inactiveTextColor: '#777777'
      }
    });

    const uiAdminTheme = createUiAdminTheme(muiTheme);

    const uiApolloTheme = createUiApolloTheme(muiTheme, {
      pagination: {
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 50, 100]
      }
    });

    const uiFormikTheme = createUiFormikTheme(muiTheme, {
      Fields: {
        fieldStyle: {
          marginBottom: `${spacing}px`
        }
      },
      SubmitButton: {
        defaultProps: {
          variant: 'contained'
        }
      }
    });

    const theme = {
      ...muiTheme,
      ...uiTheme,
      ...uiAdminTheme,
      ...uiApolloTheme,
      ...uiFormikTheme
    };

    console.debug('THEME', theme);

    return theme;
  }, []);
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends UiTheme, UiAdminTheme, UiApolloTheme, UiFormikTheme {}
}

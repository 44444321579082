import { Box, Typography } from '@mui/material';
import React from 'react';

function toInitials(name: string, max: number): string {
  const uppercase = name.split('').filter(char => char.match(/[A-Z]/));
  if (uppercase.length >= max) {
    return uppercase.slice(0, max).join('');
  }

  return name
    .split(' ')
    .map(word => word.trim())
    .filter(word => word !== '')
    .map(word => word[0].toLocaleUpperCase())
    .slice(0, max)
    .join('');
}

export function Initials({ fullName }: { fullName: string }) {
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        backgroundColor: '#ddd',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography>{toInitials(fullName, 2)}</Typography>
    </Box>
  );
}

import { projection } from '@everlutionsk/helpers';

export const prettifyStatus = projection({
  invited: 'Invited',
  activated: 'Activated',
  suspended: 'Suspended',
  requested: 'Requested',
  deleted: 'Deleted'
});

export const prettifyLicence = projection({
  trial: 'Trial',
  full: 'Full'
});

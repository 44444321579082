import { gql } from '@apollo/client';

export const journalistFragment = gql`
  fragment JournalistFragment on Journalist {
    id
    email
    firstName
    lastName
    fullName
    jobTitle
    publication
    phone
    country
    status
    callTemplate
    emailTemplate
    thankTemplate
    activeCallTemplate
    activeEmailTemplate
    activeThankTemplate
    fileIdentifier
  }
`;

import React from 'react';
import { Route, Routes } from '@everlutionsk/ui-router';
import { routingPatterns } from '../../Routes';
import { AddJournalist } from './AddJournalist';
import { DetailJournalist } from './DetailJournalist';
import { JournalistList } from './JournalistList';
import { VoiceNote } from './VoiceNote';
import { EditJournalist } from './EditJournalist';

export function Journalists() {
  return (
    <Routes>
      <Route path={routingPatterns.journalists.all} element={<JournalistList />} />
      <Route path={routingPatterns.journalists.add} element={<AddJournalist />} />
      <Route path={routingPatterns.journalists.detail} element={<DetailJournalist />} />
      <Route path={routingPatterns.journalists.edit} element={<EditJournalist />} />
      <Route path={routingPatterns.journalists.voiceNote} element={<VoiceNote />} />
    </Routes>
  );
}

import { format, formatDistance } from 'date-fns';

export function timeTo(date: Date): string {
  return formatDistance(date, new Date(), { addSuffix: true });
}

export function timeSince(date: Date): string {
  return formatDistance(date, new Date(), { addSuffix: true });
}

export function formatDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function formatDateTime(date: string) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss ');
}

export function toPlatform(platform: string | undefined | null) {
  if (platform === 'ios') return 'Apple';
  if (platform === 'android') return 'Android';

  return '-';
}

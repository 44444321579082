import { gql, useMutation, useQuery } from '@apollo/client';
import { useCitadel } from '@everlutionsk/citadel-react';
import { SetNewPasswordScreen } from '@everlutionsk/ui-admin';
import { useNavigate } from '@everlutionsk/ui-router';
import React from 'react';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useParams } from '@everlutionsk/ui-router';
import { AuthBackground } from '../../components/AuthBackground';
import logo from './logo.svg';

interface Props {
  readonly mode: 'acceptInvitation' | 'completePasswordReset';
}

export function SetNewPassword({ mode }: Props) {
  const citadel = useCitadel();
  const navigate = useNavigate();
  const { token } = useParams(['token']);

  const { data } = useQuery(query, { variables: { token } });

  const [acceptInvitation] = useFlashMutation(acceptInvitationMutation, {
    successMsg: 'Invitation was successfully accepted',
    errorMsg: `Can not accepted invitation`
  });

  const [resetPassword] = useFlashMutation(CompleteResetPasswordMutation, {
    successMsg: 'Password reset submitted',
    errorMsg: `Error resetting your password!`
  });

  const [updateLastActivity] = useMutation(updateLastLoginMutation);

  return (
    <AuthBackground>
      {data && (
        <SetNewPasswordScreen
          email={data.adminOneConfirmation.email}
          token={token}
          mode={mode}
          onAcceptInvitation={async credentials => {
            await acceptInvitation({
              variables: {
                input: {
                  password: credentials.password,
                  token: credentials.token
                }
              }
            });

            await citadel.signIn({
              login: data.adminOneConfirmation.email,
              password: credentials.password
            });
            await updateLastActivity();

            navigate('/journalists');
          }}
          onCompletePasswordReset={async credentials => {
            resetPassword({
              variables: {
                input: {
                  newPassword: credentials.password,
                  email: data.adminOneConfirmation.email,
                  token
                }
              }
            });
            await citadel.signIn(credentials);
            navigate('/journalists');
          }}
          logo={logo}
          companyName="Roxhill Media"
          description={{
            acceptInvitation: 'Sign in to use Travel Notes',
            createNewPassword: 'Create new password to use Travel Notes'
          }}
        />
      )}
    </AuthBackground>
  );
}

const query = gql<AdminOneAcceptInvitationGQL>`
  query AdminOneAcceptInvitation($token: ID!) {
    adminOneConfirmation(token: $token) {
      id
      firstName
      email
    }
  }
`;

const acceptInvitationMutation = gql<AcceptAdminInvitationGQL>`
  mutation AcceptAdminInvitation($input: AcceptInvitationInput!) {
    acceptAdminInvitation(input: $input) {
      id
    }
  }
`;

const updateLastLoginMutation = gql<UpdateLastActiveAfterAdminAcceptInvitationGQL>`
  mutation UpdateLastActiveAfterAdminAcceptInvitation {
    updateLastActiveAt
  }
`;

const CompleteResetPasswordMutation = gql<CompleteResetPasswordGQL>`
  mutation CompleteResetPassword($input: CompleteResetPasswordInput!) {
    completeResetPassword(input: $input) {
      id
    }
  }
`;

import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CitadelClient, CitadelProvider, useCitadel } from '@everlutionsk/citadel-react';
import { FileJetClient, FileJetProvider } from '@everlutionsk/filejet-react';
import { UiProvider } from '@everlutionsk/ui';
import { AdminLayout, AdminLayoutNavGroup } from '@everlutionsk/ui-admin';
import { Route, Router, Routes } from '@everlutionsk/ui-router';
import {
  Logout as LogoutIcon,
  ManageAccounts as AdminAccountsIcon,
  Person as JournalistAccountsIcon,
  SupervisorAccount as ManageAccountsIcon
} from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { AuthenticatedRoute } from '../components/AuthenticatedRoute';
import { routingPatterns } from '../Routes';
import { useTheme } from '../theme';
import { Admins } from './Admins';
import { Auth } from './Auth';
import { Journalists } from './Journalists';
import { Managers } from './Managers';
import logo from './roxhil-logo.svg';

interface Props {
  readonly apollo: ApolloClient<any>;
  readonly citadel: CitadelClient;
  readonly filejet: FileJetClient;
}

export function App({ apollo, citadel, filejet }: Props) {
  const theme = useTheme();

  return (
    <UiProvider theme={theme} flashMessages historyState>
      <CitadelProvider client={citadel}>
        <ApolloProvider client={apollo}>
          <FileJetProvider client={filejet}>
            <Router>
              <Routes>
                <Route path={routingPatterns.auth.pattern} element={<Auth />} />
                <AuthenticatedRoute path={'*'} element={<Pages />} />
              </Routes>
            </Router>
          </FileJetProvider>
        </ApolloProvider>
      </CitadelProvider>
    </UiProvider>
  );
}

function Pages() {
  const citadel = useCitadel();

  return (
    <AdminLayout
      toolbarTitle={<img src={logo} alt="Roxhill Travel Notes" />}
      toolbarAction={
        <Box display="flex" flexDirection="row" alignItems="center">
          <Divider
            sx={{ mr: 2, backgroundColor: 'text.disabled' }}
            orientation="vertical"
            flexItem
            variant="inset"
          />
          <Box mr={8}>
            <Typography variant="body2" color="text.disabled" sx={{ textTransform: 'uppercase' }}>
              logged in as
            </Typography>
            <Typography variant="body1" color="white">
              {citadel.identity?.email}
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Sign out">
              <IconButton
                size="small"
                sx={{ backgroundColor: '#F8C147' }}
                onClick={() => citadel.signOut()}
                aria-label="Sign out"
              >
                <LogoutIcon sx={{ color: 'palette.text.secondary' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      }
      navGroups={navigation}
      navVariant="regular"
      navWidth={250}
      toolbarVariant="regular"
    >
      <Routes>
        <Route path={routingPatterns.journalists.pattern} element={<Journalists />} />
        <Route path={routingPatterns.managers.pattern} element={<Managers />} />
        <Route path={routingPatterns.admins.pattern} element={<Admins />} />
      </Routes>
    </AdminLayout>
  );
}

const navigation: AdminLayoutNavGroup[] = [
  {
    label: 'Clients',
    items: [
      { label: 'Journalists', to: '/journalists', icon: <JournalistAccountsIcon /> },
      { label: 'PRs', to: '/prs', icon: <ManageAccountsIcon /> }
    ]
  },
  {
    label: 'Users',
    items: [{ label: 'Admins', to: '/admins', icon: <AdminAccountsIcon /> }]
  }
];

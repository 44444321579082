import { createAppRouting, prefixRouting as prefix } from '@everlutionsk/ui-router';

export const routing = createAppRouting(() => ({
  auth: prefix('auth', {
    signIn: 'sign-in',
    forgottenPassword: 'forgotten-password'
  }),
  journalists: prefix('journalists', {
    all: '',
    add: 'add',
    detail: (id: string) => `detail/${id}`,
    edit: (id: string) => `detail/${id}/edit/`,
    voiceNote: (id: string, voiceNoteId: string) => `edit/${id}/voice-note/${voiceNoteId}`
  }),
  managers: prefix('prs', {
    all: '',
    add: 'add',
    detail: (id: string) => `detail/${id}`,
    edit: (id: string) => `detail/${id}/edit/`
  }),
  admins: prefix('admins', {
    all: '',
    add: 'add',
    edit: (id: string) => `edit/${id}`
  }),
  changePassword: 'change-password'
}));

export const routingPatterns = createAppRouting(() => ({
  auth: {
    pattern: 'auth/*',
    signIn: 'sign-in',
    forgottenPassword: 'forgotten-password',
    resetPasswordConfirmation: 'reset-password-new/:token',
    acceptInvitation: 'invitation/:token'
  },
  journalists: {
    pattern: 'journalists/*',
    all: '',
    add: 'add',
    detail: 'detail/:id',
    edit: 'detail/:id/edit',
    voiceNote: 'edit/:id/voice-note/:voiceNoteId'
  },
  managers: {
    pattern: 'prs/*',
    all: '',
    add: 'add',
    detail: 'detail/:id',
    edit: 'detail/:id/edit'
  },
  admins: {
    pattern: 'admins/*',
    all: '',
    add: 'add',
    edit: 'edit/:id'
  },
  changePassword: 'change-password'
}));

import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';

export function AuthBackground({ children }: { children: ReactNode }) {
  return <Background>{children}</Background>;
}

const Background = styled(Box)`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #041e2d;
`;

import { gql, useQuery } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { LocaleDate, useHistoryState } from '@everlutionsk/ui';
import { Card, CardContent, CardWithTabs, EditButton, Page } from '@everlutionsk/ui-admin';
import { useFlashMutation } from '@everlutionsk/ui-apollo';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Initials } from '../../components/Initials';
import { StatusBadge } from '../../components/StatusBadge';
import { toFileUrl } from '../../file';
import { managerFragment } from '../../fragments/manager';
import { routing } from '../../Routes';
import { NotRelevantList } from './NotRelevantList';
import { RepliesList } from './RepliesList';

export function DetailManager() {
  const { id } = useParams(['id']);
  const navigate = useNavigate();
  const [tab, setTab] = useHistoryState<'replies' | 'notRelevant'>('managerDetail.tab', 'replies');

  const { data } = useQuery(query, { variables: { id } });

  const [resendInvite] = useFlashMutation(resendInviteMutation, {
    successMsg: 'Invitation resend successful!',
    errorMsg: 'User could not be invited!',
    variables: {
      id
    }
  });

  const [invite] = useFlashMutation(inviteRequested, {
    variables: { id },
    successMsg: 'PR user successfully invited!',
    errorMsg: 'PR user can not be invited!'
  });

  const [suspend] = useFlashMutation(suspendMutation, {
    variables: { id },
    successMsg: 'PR user is suspended!',
    errorMsg: 'Something went wrong!'
  });

  const [activate] = useFlashMutation(activateMutation, {
    variables: { id },
    successMsg: 'PR user is activated!',
    errorMsg: 'Something went wrong!'
  });

  return (
    <Page
      title={data?.managerOne.fullName}
      breadcrumbs={[{ label: 'Prs', to: routing.managers.all }, { label: `Detail` }]}
      action={
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={2}>
            {data?.managerOne.status === 'requested' && (
              <Button variant="contained" color="primary" onClick={() => invite()}>
                Send Invitation
              </Button>
            )}

            {data?.managerOne.status === 'invited' && (
              <Button color="primary" variant="contained" onClick={() => resendInvite()}>
                Resend invite email
              </Button>
            )}

            {data?.managerOne.status === 'activated' && (
              <Button color="primary" variant="contained" onClick={() => suspend()}>
                Suspend account
              </Button>
            )}

            {data?.managerOne.status === 'suspended' && (
              <Button color="primary" variant="contained" onClick={() => activate()}>
                Activate account
              </Button>
            )}
          </Box>
          {data?.managerOne.status != null && <StatusBadge status={data?.managerOne.status} />}
        </Box>
      }
    >
      <Card sx={{ borderTop: 'unset' }}>
        {populated(data) && (
          <CardContent>
            <Card title="General info">
              <CardContent>
                <Stack direction="row" justifyContent="space-between" spacing={10}>
                  <Stack direction="row" spacing={10} alignItems="center">
                    {data.managerOne.fileIdentifier ? (
                      <img
                        style={{
                          borderRadius: '50%'
                        }}
                        width={100}
                        height={100}
                        src={
                          data.managerOne.fileIdentifier
                            ? toFileUrl({
                                file: {
                                  id: data.managerOne.fileIdentifier,
                                  mutation: `resize_400x400min,crop_400x400`
                                }
                              })
                            : undefined
                        }
                        alt="Profile picture"
                      />
                    ) : (
                      <Initials fullName={data.managerOne.fullName} />
                    )}
                    <Stack direction="column">
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Name{' '}
                        </Typography>
                        : {data.managerOne.fullName}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Job title{' '}
                        </Typography>
                        : {data.managerOne.jobTitle}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Company{' '}
                        </Typography>
                        : {data.managerOne.company}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Email{' '}
                        </Typography>
                        : {data.managerOne.email}
                      </Typography>
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Telephone{' '}
                        </Typography>
                        : {data.managerOne.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    {data?.managerOne.status === 'invited' ||
                      (data.managerOne.status === 'activated' && (
                        <Stack direction="column">
                          <Typography variant="body1">
                            <Typography variant="h3" component="span">
                              Invite sent:{' '}
                            </Typography>
                            <LocaleDate date={data?.managerOne.invitedAt} variant="date" />
                          </Typography>
                        </Stack>
                      ))}

                    {data?.managerOne.status === 'activated' && (
                      <>
                        <Stack direction="column">
                          <Typography variant="body1">
                            <Typography variant="h3" component="span">
                              Last login:{' '}
                            </Typography>
                            {data?.managerOne.lastActiveAt ? (
                              <LocaleDate date={data?.managerOne.lastActiveAt} variant="date" />
                            ) : (
                              'Never'
                            )}
                          </Typography>
                        </Stack>
                      </>
                    )}

                    <Stack direction="column">
                      <Typography variant="body1">
                        <Typography variant="h3" component="span">
                          Account type:{' '}
                        </Typography>
                        {data?.managerOne.licence}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" justifyContent="flex-end">
                    <EditButton
                      onClick={() => navigate(routing.managers.edit(data.managerOne.id))}
                    />
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        )}
        <CardContent>
          <CardWithTabs
            tabs={{
              value: tab,
              onChange: setTab,
              tabs: [
                {
                  value: 'replies',
                  label: 'Replies',
                  node: <RepliesList managerId={id} />
                },
                {
                  value: 'notRelevant',
                  label: 'Not relevant',
                  node: <NotRelevantList managerId={id} />
                }
              ]
            }}
          />
        </CardContent>
      </Card>
    </Page>
  );
}

const query = gql<ManagerOneDetailGQL>`
  query ManagerOneDetail($id: ID!) {
    managerOne(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

const inviteRequested = gql<InviteRequestedManagerGQL>`
  mutation InviteRequestedManager($id: ID!) {
    inviteRequestedManager(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

const resendInviteMutation = gql<ResendManagerInviteGQL>`
  mutation ResendManagerInvite($id: ID!) {
    resendManagerInvite(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

const suspendMutation = gql<SuspendManagerGQL>`
  mutation SuspendManager($id: ID!) {
    suspendManager(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

const activateMutation = gql<ActivateManagerGQL>`
  mutation ActivateManager($id: ID!) {
    activateManager(id: $id) {
      ...ManagerFragment
    }
  }
  ${managerFragment}
`;

import { Route, Routes } from '@everlutionsk/ui-router';
import React from 'react';
import { routingPatterns } from '../Routes';
import { SignIn } from './SignIn';
import { SetNewPassword } from './SignIn/SetNewPassword';

export function Auth() {
  return (
    <Routes>
      <Route path={routingPatterns.auth.signIn} element={<SignIn />} />
      <Route
        path={routingPatterns.auth.resetPasswordConfirmation}
        element={<SetNewPassword mode="completePasswordReset" />}
      />
      <Route
        path={routingPatterns.auth.acceptInvitation}
        element={<SetNewPassword mode="acceptInvitation" />}
      />
    </Routes>
  );
}

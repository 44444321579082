import React from 'react';
import { Route, Routes } from '@everlutionsk/ui-router';
import { routingPatterns } from '../../Routes';
import { ManagerList } from './ManagerList';
import { AddManager } from './AddManager';
import { EditManager } from './EditManager';
import { DetailManager } from './DetailManager';

export function Managers() {
  return (
    <Routes>
      <Route path={routingPatterns.managers.all} element={<ManagerList />} />
      <Route path={routingPatterns.managers.add} element={<AddManager />} />
      <Route path={routingPatterns.managers.edit} element={<EditManager />} />
      <Route path={routingPatterns.managers.detail} element={<DetailManager />} />
    </Routes>
  );
}

import { gql } from '@apollo/client';

export const managerFragment = gql`
  fragment ManagerFragment on Manager {
    id
    firstName
    lastName
    fullName
    jobTitle
    company
    brand
    country
    phone
    email
    showPhone
    showEmail
    licence
    status
    invitedAt
    lastActiveAt
    fileIdentifier
    licenceExpiredAt
  }
`;
